import moment from "moment";
import { Fragment, useContext, useState } from "react";
import { LanguageContext } from "../context/LanguageContext";

import "./MonthSelector.css";

const firstYear = 2016;
const now = new Date();
const currentYear = now.getFullYear();
const currentMonth = now.getMonth();

const  MonthSelector = ({disabled = false, onSelect, allowCurrentMonth = false}) => {
  
  const { language } = useContext(LanguageContext);
  const [year, setYear] = useState(currentYear);
  const isPreviousDisabled = year === firstYear;
  const isNextDisabled = year === currentYear;
  const months = Array.apply(0, Array(12)).map((_, index) => { return {"index": index, "text": moment().locale(language).month(index).format("MMM") }});

  const onNext = () => {
    if (year === currentYear) {
      return;
    }

    setYear(year + 1);
  };

  const onPrevious = () => {
    if (year === firstYear) {
      return;
    }

    setYear(year - 1);
  };

  const onMonthSelected = (month) => {
    onSelect(year, month);
  }

  return (
    <Fragment>
      <div className="month-selector">
        <div className="years">
          <div className={`item${disabled || isPreviousDisabled ? " disabled": ""}`} role="button" onClick={isPreviousDisabled ? null : onPrevious}>
            <i className="fas fa-chevron-circle-left"></i>
          </div>
          <div className="item year">
            {year}
          </div>
          <div className={`item${disabled || isNextDisabled ? " disabled" : ""}`} role="button" onClick={isNextDisabled ? null : onNext}>
            <i className="fas fa-chevron-circle-right" ></i>
          </div>
        </div>
        <div className="months">
          {months.map(m => {
            const itemDisabled = disabled || (year === currentYear && currentMonth < m.index) || (!allowCurrentMonth && currentMonth === m.index);
            return (
              <div key={m.index} className={`item text-capitalize month${itemDisabled ? " disabled" : ""}`} onClick={itemDisabled ? null : () => onMonthSelected(m.index)} role="button">
                {m.text.replace(".", "")}
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );

}

export default MonthSelector;