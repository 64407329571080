import React, { useState } from "react";
import { useQuery } from "react-query";
import PrintService from "../services/PrintService";
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, CartesianGrid, LabelList } from "recharts";
import { FormattedMessage, useIntl } from "react-intl";

import "./CopyShopDailyPrints.css";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import CustomSpinner from "../../commons/components/Spinner";
import Section from "../../commons/components/Section";

const defaultDays = 7;

const CopyShopDailyPrints = ({id}) => {

  const [selectedDays, setSelectedDays] = useState(defaultDays);
  const [data, setData] = useState([]);
  const intl = useIntl();

  const result = useQuery(["prints/daily", id, selectedDays], () => PrintService.daily(id, selectedDays), {
    enabled: id !== undefined,
    onSuccess: (response) => setData(response.data)
  });

  if (!id || !Array.isArray(data)) {
    return null;
  }

  const onClickDays = (e) => {

    if (e?.target?.dataset?.days) {
      setSelectedDays(parseInt(e.target.dataset.days));
    } else {
      setSelectedDays(defaultDays);
    }
    
  }

  const onExport = (e) => {

    const rows = [];
    rows.push(Object.keys(data[0]).filter(k => k !== "prints").join() + "\n");
    data.forEach(r => rows.push(`${r.day},${r.pagesPrinted}\n`));

    const blob = new Blob(rows, {type: "text/csv"});
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.download = "dayly_prints.csv";
    link.href = url;
    link.click();
  };

  const renderLabel = (value) => {
    return intl.formatNumber(value)
  }

  const barSize = selectedDays === 30 ? 15 : 25;

  const chart = (
    <ResponsiveContainer className="mb-4" width="100%" minHeight={250} maxHeight={360}>
      <BarChart width={600} height={300} data={data} margin={{
        top: 40,
        right: 30,
        left: 20,
        bottom: 45,
      }}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="day" angle={-90} textAnchor="end" interval={0} tick={{fill: "#516b85"}} />
        <YAxis width={50} tick={{fill: "#516b85"}} />
        <Legend wrapperStyle={{position: "relative", marginTop: "45px"}} formatter={renderLegendText}/>
        <Bar dataKey="pagesPrinted" barSize={barSize} fill="#516b85" minPointSize={5} >
          <LabelList
            position="top"
            fill="#516b85"
            formatter={renderLabel}
          />
          </Bar>
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <Section headerKey="copyShop.daily.title">
      <Row>
        <Col>
          {result.isLoading ? <CustomSpinner messageKey="copyShop.daily.loading" /> : null }
          <div className="d-flex justify-content-between">
            <ButtonGroup>
              <Button size="sm" className={selectedDays === 7 ? "active": null} data-days="7" onClick={onClickDays} outline>
                <FormattedMessage id="copyShop.daily.filter.7" />
              </Button>
              <Button size="sm" className={selectedDays === 14 ? "active": null} data-days="14" onClick={onClickDays} outline>
                <FormattedMessage id="copyShop.daily.filter.14" />
              </Button>
              <Button size="sm" className={selectedDays === 30 ? "active": null} data-days="30" onClick={onClickDays} outline>
                <FormattedMessage id="copyShop.daily.filter.30" />
              </Button>
            </ButtonGroup>
            <Button size="sm" onClick={onExport}>
              <i className="fas fa-file-download"></i>
              <span className="d-none d-md-inline ms-2"><FormattedMessage id="copyShop.daily.buttons.export" /></span>
            </Button>
          </div>
          {chart}
        </Col>
      </Row>
    </Section>
  );

};

export default CopyShopDailyPrints;

const renderLegendText = (value, entry) => {
  const { color } = entry;

  return <span style={{ color }}><FormattedMessage id={`copyShop.daily.${value}`} /></span>;
};
