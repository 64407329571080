import React, { useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";

export const PasswordInput = (props) => {

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const icon = passwordShown ? "fa fa-eye": "fa fa-eye-slash";

  return (
    <InputGroup>
      <Input type={passwordShown ? "text" : "password"} {...props} />
      <InputGroupText role="button"onClick={togglePasswordVisiblity}>
          <i className={icon} aria-hidden="true" />
      </InputGroupText>
    </InputGroup>
  );
}