import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ListPagination from '../../commons/components/ListPagination';
import CustomSpinner from '../../commons/components/Spinner';
import { handleApiErrors } from '../../commons/functions';
import PricingConfigService from '../services/PricingConfigService';
import { PricingConfigForm } from './PricingConfigForm';
import Secured from '../../commons/components/Secured';
import Moment from 'react-moment';

import './PricingConfigList.css';
import DataTable from '../../commons/components/DataTable';
import WarningMessage from '../../commons/components/WarningMessage';

const dataTableColumns = [
  {
    headerKey: "pricing.pricingConfig.field.name",
    selector: row => row.name
  },
  {
    headerKey: "pricing.pricingConfig.field.startDate",
    selector: row => <Moment format='DD/MM/YYYY'>{row.startDate}</Moment>
  },
  {
    headerKey: "pricing.pricingConfig.field.endDate",
    selector: row => <Moment format='DD/MM/YYYY'>{row.endDate}</Moment>
  }
];

export const PricingConfigList = ({onSelect, selected}) => {

  const pageSize = 10;
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState({total: 0, list: []});
  const [selectedRow, setSelectedRow] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openTecInfo, setOpenTecInfo] = useState(false);
  
  const queryClient = useQueryClient();
  const result = useQuery(["pricingDefinitions", currentPage, pageSize], () => PricingConfigService.list(currentPage, pageSize), {
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });
  
  const addMutation = useMutation((data) => PricingConfigService.create(data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "pricing.pricingConfig.msg.create"}));
      queryClient.invalidateQueries("pricingDefinitions");
      toggleForm();
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "pricing.pricingConfig.error.create"))
  });

  const deleteMutation = useMutation((identifier) => PricingConfigService.delete(identifier), {
    onSuccess: (response, [identifier], context) => {
      toast.success(intl.formatMessage({id: "pricing.pricingConfig.msg.delete"}));
      queryClient.invalidateQueries("pricingDefinitions");
      if (identifier === selected) {
        onSelect(identifier);
      }
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "pricing.pricingConfig.error.delete")),
    onSettled: () => {
      toggleDelete();
    }
  });

  const updateMutation = useMutation(([identifier, data]) => PricingConfigService.update(identifier, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "pricing.pricingConfig.msg.update"}));
      queryClient.invalidateQueries("pricingDefinitions");
      toggleForm();
    },
    onError: (error, vars, context) => {
      toast.error(handleApiErrors(intl, error, "pricing.pricingConfig.error.update"));
    }
  });

  useEffect(() => {
    if (!openForm) {
      setSelectedRow(null);
    }
  }, [openForm]);

  useEffect(() => {
    if (!openDeleteConfirm) {
      setSelectedRow(null);
    }
  }, [openDeleteConfirm]);

  const toggleForm = () => {
    setOpenForm(oldValue =>!oldValue);
  };

  const toggleDelete = () => {
    setOpenDeleteConfirm(oldValue =>!oldValue);
  };

  const toggleTecInfo = () => {
    setOpenTecInfo(oldValue => !oldValue);
  };

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  const handleSave = (data) => {
    if (selectedRow) {
      updateMutation.mutate([selectedRow.id, data]);
    } else {
      addMutation.mutate(data);
    }
  };

  const handleDelete = () => {
    deleteMutation.mutate([selectedRow.id]);
  };

  const handleConfirmDelete = (row) => {
    setSelectedRow(row);
    toggleDelete();
  };

  const handleUpdate = (row) => {
    setSelectedRow(row);
    toggleForm();
  }

  const handleOnSelect = (row, rowIndex) => {
    onSelect(row.id, rowIndex === 0);
  }

  let content = null;

  if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="pricing.pricingConfig.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id = "pricing.pricingConfig.error.loading" />
    );
  } else {

    let pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

    content = (
      <Fragment>
        <DataTable responsive bordered hover className='pricing-config-table' data={page.list} columns={dataTableColumns} onSelect={handleOnSelect} selected={row =>selected === row.id}
          actionsHeaderStyle={{width: "60px"}} securedActions={true} renderRowActions={(row, rowIndex) => {

            if (rowIndex !== 0) {
              return (
                <i className="fas fa-info-circle me-2" role="button" onClick={toggleTecInfo}></i>
              );
            }

            return (
              <Fragment>
                <i className="fas fa-edit me-2" role="button" onClick={() => handleUpdate(row)}></i>
                <i className="fas fa-trash-alt" role="button" onClick={() => handleConfirmDelete(row)}></i>
              </Fragment>
            );
        }} />
        {pagination}
      </Fragment>
    );
  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h2 className='section-title fs-5 flex-grow-1'><FormattedMessage id="pricing.pricingConfig.title" /></h2>
        <Secured>
          <Button className='ms-2' size='sm' disabled={result.isLoading || addMutation.isLoading || updateMutation.isLoading} onClick={toggleForm}>
            <i className='fas fa-plus me-0 me-md-2' />
            <span className='d-none d-md-inline'>
              <FormattedMessage id="pricing.pricingConfig.buttons.add" />
            </span>
          </Button>
        </Secured>
      </div>
      <div className='section-content'>
        {content}
      </div>
      {openDeleteConfirm ? <DeletePricingConfigModal open={openDeleteConfirm} toggle={toggleDelete} onConfirm={handleDelete} disabled={deleteMutation.isLoading} /> : null}
      {openForm ? <PricingConfigModalForm open={openForm} toggle={toggleForm} onSave={handleSave} data={selectedRow} disabled={addMutation.isLoading || updateMutation.isLoading}
        headerKey={selectedRow ? "pricing.pricingConfig.edit.title": "pricing.pricingConfig.add.title"} /> : null }
      {openTecInfo ? <TechnicalInfoModal open={openTecInfo} toggle={toggleTecInfo} /> : null }
    </div>
  );
}

const DeletePricingConfigModal = ({open, toggle, onConfirm, disabled = false}) => {

    return (
      <Modal isOpen={open} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <FormattedMessage id="confirm" />
        </ModalHeader>
        <ModalBody>
          <WarningMessage messageKey="pricing.pricingConfig.delete.warning" />
          <FormattedMessage id="pricing.pricingConfig.delete" />
        </ModalBody>
        <ModalFooter>
          <div className='d-flex justify-content-end'>
            <Button color='danger' onClick={onConfirm} disabled={disabled}>
              <FormattedMessage id="pricing.pricingConfig.buttons.delete" />
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
};

const PricingConfigModalForm = ({open, toggle, headerKey, data, disabled = false, onSave}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={headerKey} />
      </ModalHeader>
      <ModalBody>
        {data?.id ? <WarningMessage messageKey="pricing.pricingConfig.msg.edit.warning" /> : null}
        <PricingConfigForm id="pricing-form" onSave={onSave} defaultValues={data} disabled={disabled} />
      </ModalBody>
      <ModalFooter>
      <div className='d-flex justify-content-end'>
        <Button color='primary' disabled={disabled} type="submit" form="pricing-form">
          <FormattedMessage id="pricing.pricingConfig.buttons.save" />
        </Button>
      </div>
      </ModalFooter>
    </Modal>
  );
};

const TechnicalInfoModal = ({open, toggle}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="info" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="pricing.pricingConfig.msg.technicalContact" />
      </ModalBody>
    </Modal>
  );
}