import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children, authData }) => {
  
  const [auth, setAuth] = useLocalStorage("auth", authData);
  
  const navigate = useNavigate();

  const login = async (data) => {
    setAuth(data);
    navigate("/", { replace: true });
  };

  const logout = () => {
    setAuth(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      auth,
      login,
      logout
    }),
    [auth]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
