import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { Page } from '../../commons/components/Page';
import { CopyShopList } from '../components/CopyShopList';

export const CopyShopPage = (props) => {

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <h1 className='page-header fs-4'><FormattedMessage id="home" /></h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <CopyShopList />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}