import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { InputErrors } from "../../commons/components/InputErrors";

export const PricingLevelForm = ({id, onSave, max, defaultValues, disabled}) => {

  const [maxSelected, setMaxSelected] = useState(defaultValues?.maximum === max);
  const { register, handleSubmit, setError, setFocus, setValue, formState: { errors } } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });

  useEffect(() => {
    setFocus("minimum");
  }, [setFocus]);

  const { ref: refMin, ...registerMin } = register('minimum', { required: true, min: 1, max: max });
  const { ref: refMax, ...registerMax } = register('maximum', { required: true, min: 1, max: max });
  const { ref: refPrice, ...registerPrice } = register('price', { required: true, min: 0 });

  const onSubmit = (data) => {
    onSave(data, setError);
  };

  const handleMaxClick = () => {
    if (disabled) {
      return;
    }
    
    if (maxSelected) {
      setValue("maximum", 0);
    } else {
      setValue("maximum", max);
    }
    setMaxSelected(!maxSelected);
  }

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for='minimum'>
          <FormattedMessage id="pricing.pricingLevel.field.minimum" />
        </Label>
        <Input id="minimum"  name="minimum" innerRef={refMin} {...registerMin} type="number" min="1" max={max} disabled={disabled} />
        <InputErrors fieldName="minimum" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='maximum'>
          <FormattedMessage id="pricing.pricingLevel.field.maximum" />
        </Label>
        <InputGroup>
          <Input id="maximum"  name="maximum" innerRef={refMax} {...registerMax} type="number" min="1" max={max} disabled={disabled || maxSelected} />
          <InputGroupText role="button" onClick={handleMaxClick}>
            <i className={`me-2 fas ${maxSelected ? "fa-check-square" : "fa-square" }`} ></i>
            <FormattedMessage id="pricing.pricingLevel.selectMax" />
          </InputGroupText>
        </InputGroup>
        <InputErrors fieldName="maximum" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='price'>
          <FormattedMessage id="pricing.pricingLevel.field.price" />
        </Label>
        <Input id="price"  name="price" innerRef={refPrice} {...registerPrice} type="number" min="0" step="any"  disabled={disabled} />
        <InputErrors fieldName="price" errors={errors} />
      </FormGroup>
    </Form>
  );
};