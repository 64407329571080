import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { Page } from '../../commons/components/Page';
import { PricingConfigList } from '../components/PricingConfigList';
import { PricingLevelList } from '../components/PricingLevelList';
import { VatConfigList } from '../components/VatConfigList';

export const PricingPage = (props) => {

  const [selectedDefinition, setSelectedDefinition] = useState();
  const [selectedIsEditable, setSelectedIsEditable] = useState(false);

  const onSelectDefinition = (identifier, isEditable) => {
    if (identifier === selectedDefinition) {
      setSelectedDefinition(null);
      setSelectedIsEditable(false);
    } else {
      setSelectedDefinition(identifier);
      setSelectedIsEditable(isEditable);
    }
    
  };

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <h1 className='page-header fs-4'><FormattedMessage id="pricing.title" /></h1>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="6">
            <PricingConfigList onSelect={onSelectDefinition} selected={selectedDefinition} />
          </Col>
          <Col xs="12" lg="6">
            <PricingLevelList configId={selectedDefinition} isEditable={selectedIsEditable} />
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="6">
            <VatConfigList />
          </Col>
        </Row>
      </Container>
    </Page>
  );
}