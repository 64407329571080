import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { LogoGreen } from '../../commons/components/icons/LogoGreen';
import { LanguageSelector } from '../../commons/components/LanguageSelector';
import { useAuth } from '../../commons/hooks/useAuth';
import { ForgotPasswordForm } from '../components/ForgotPasswordForm';
import { FormattedMessage } from 'react-intl';


export const ForgotPasswordPage = (props) => {

  const { auth } = useAuth();

  if (auth) {
    return (
      <Navigate to="/" />
    );
  }

  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
      <div>
        <LogoGreen width='300px' className="mb-2" />
        <ForgotPasswordForm />
        <div className="mt-2">
          <Link to="/login">
            <FormattedMessage id="forgotPassword.buttons.back" />
          </Link>
        </div>
        <div className='d-flex justify-content-center mt-2'>
          <LanguageSelector theme='light'/>
        </div>
      </div>
    </div>
  );
}