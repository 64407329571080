import { FormattedMessage } from "react-intl";
import { Alert } from "reactstrap";

const WarningMessage = ({messageKey}) => {
  return (
    <Alert color='warning'>
      <div className="d-flex">
        <i className="fas fa-exclamation-triangle me-1 text-warning fs-4 me-2 align-self-center"></i>
        <span className="small">
          <FormattedMessage id={messageKey} />
        </span>
      </div>
    </Alert>
  );
};

export default WarningMessage;