import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { LogoGreen } from '../../commons/components/icons/LogoGreen';
import { LanguageSelector } from '../../commons/components/LanguageSelector';
import { useAuth } from '../../commons/hooks/useAuth';
import { LoginForm } from '../components/LoginForm';
import { FormattedMessage } from 'react-intl';


export const LoginPage = (props) => {

  const { auth } = useAuth();

  if (auth) {
    return (
      <Navigate to="/" />
    );
  }

  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
      <div>
        <LogoGreen width='300px' className="mb-2" />
        <LoginForm />
        <div className='d-flex justify-content-end mt-2'>
          <Link to="/forgot-password">
            <FormattedMessage id="login.buttons.forgotPassword" />
          </Link>
        </div>
        <div className='d-flex justify-content-center mt-2'>
          <LanguageSelector theme='light'/>
        </div>
      </div>
    </div>
  );
}