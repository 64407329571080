import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { IntlProvider } from 'react-intl';
import messages from './i18n/messages';

import { CopyShopPage } from './copyshops/containers/CopyshopPage';
import { HelpPage } from './help/containers/HelpPage';
import { PricingPage } from './pricing/containers/PricingPage';
import { InvoiceInfoPage } from './invoiceInfo/containers/InvoiceInfoPage';
import { UserSettingsPage } from './user/containers/UserSettingsPage';
import { LoginPage } from './login/containers/LoginPage';
import { NoMatchPage } from './commons/containers/NoMatchPage';
import { LanguageContext } from './commons/context/LanguageContext';
import { AuthLayout } from './commons/components/AuthLayout';
import { CopyShopFormPage } from './copyshops/containers/CopyShopFormPage';
import { useLocalStorage } from './commons/hooks/useLocalStorage';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import { QueryClient, QueryClientProvider } from "react-query";
import { CopyShopDetailPage } from "./copyshops/containers/CopyShopDetailPage";
import 'moment/locale/fr';
import 'moment/locale/nl';
import { ForgotPasswordPage } from "./user/containers/ForgotPasswordPage";
import { ResetPasswordPage } from "./user/containers/ResetPasswordPage";

function App() {

  const [language, setLanguage] = useLocalStorage("language", "en");

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          path: "/",
          element: <CopyShopPage />,
        },
        {
          path: "/copy-shops",
          element: <CopyShopPage />,
        },
        {
          path: "/copy-shops/new",
          element: <CopyShopFormPage />,
        },
        {
          path: "/copy-shops/:id",
          element: <CopyShopFormPage />,
        },
        {
          path: "/copy-shops/:id/detail",
          element: <CopyShopDetailPage />,
        },
        {
          path: "/invoice-info",
          element: <InvoiceInfoPage />,
        },
        {
          path: "/help",
          element: <HelpPage />,
        },
        {
          path: "/pricing",
          element: <PricingPage />,
        },
        {
          path: "/settings",
          element: <UserSettingsPage />,
        },
        {
          path: "/login",
          exact: true,
          element: <LoginPage />,
        },
        {
          path: "/forgot-password",
          exact: true,
          element: <ForgotPasswordPage />,
        },
        {
          path: "/resetpassword/:resetPasswordKey",
          element: <ResetPasswordPage />,
        },
        {
          path: "*",
          element: <NoMatchPage />,
        },
      ]
    }
  ]);

  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <LanguageContext.Provider value={{language, setLanguage}}>
        <IntlProvider locale={language} messages={messages[language]}>
          <ToastContainer />
          <RouterProvider router={router} />
        </IntlProvider>
      </LanguageContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
