import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class PricingConfigService {

  static async list(page, pageSize) {
    const headers = buildApiHeaders();
    return api.get("/v1/admin/pricing-config", {params: {page: page, pageSize: pageSize}, headers: headers});
  }

  static async create(data) {
    const headers = buildApiHeaders();
    return api.put("/v1/admin/pricing-config", data, {headers: headers});
  }

  static async update(identifier, data) {
    const headers = buildApiHeaders();
    return api.post(`/v1/admin/pricing-config/${identifier}`, data, {headers: headers});
  }

  static async delete(identifier) {
    const headers = buildApiHeaders();
    return api.delete(`/v1/admin/pricing-config/${identifier}`, {headers: headers});
  }
}

export default PricingConfigService;