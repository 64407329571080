import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class PricingLevelService {

  static async list(configId) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/pricing-config/${configId}/levels`, {headers: headers});
  }

  static async create(configId, data) {
    const headers = buildApiHeaders();
    return api.put(`/v1/admin/pricing-config/${configId}/levels`, data, {headers: headers});
  }

  static async update(configId, level, data) {
    const headers = buildApiHeaders();
    return api.post(`/v1/admin/pricing-config/${configId}/levels/${level}`, data, {headers: headers});
  }

  static async delete(configId, identifier) {
    const headers = buildApiHeaders();
    return api.delete(`/v1/admin/pricing-config/${configId}/levels/${identifier}`, {headers: headers});
  }
}

export default PricingLevelService;