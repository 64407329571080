import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class UserService {

  static async changePassword(data) {
    const headers = buildApiHeaders();
    return api.post("/v1/user/change-password", data, {headers: headers});
  }

  static async forgotPassword(email) {
    return api.post("/v1/forgot-password", {email: email});
  }

  static async resetPassword(key) {
    return api.get("/v1/user/reset-password/" + key);
  }
}

export default UserService;