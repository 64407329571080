import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class PrintService {

  static async list(copyShopId, page, pageSize, filter) {
    const params = Object.assign({page: page, pageSize: pageSize}, filter);
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/copy-shops/${copyShopId}/prints`, {params: params, headers: headers});
  }

  static async calculate(copyShopId, filter) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/copy-shops/${copyShopId}/calculate`, {params: filter, headers: headers});
  }

  static async calculateToday(copyShopId) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/copy-shops/${copyShopId}/calculate/today`, {headers: headers});
  }

  static async daily(copyShopId, days) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/copy-shops/${copyShopId}/prints/daily`, {params: {days: days}, headers: headers});
  }

  static async dailyPage(copyShopId, page, pageSize) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/copy-shops/${copyShopId}/prints/daily/page`, {params: {page: page, pageSize: pageSize}, headers: headers});
  }
}

export default PrintService;