import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class CityService {

  static async list() {
    const headers = buildApiHeaders();
    return api.get("/v1/cities", {headers: headers});
  }
}

export default CityService;