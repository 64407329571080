import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { Page } from '../../commons/components/Page';
import api from '../../Api';

import '../../Skeleton.css';
import { buildApiHeaders } from '../../commons/functions';

export const HelpPage = (props) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {

    const loadHelpInfo = async () => {
      const headers = buildApiHeaders();
      return api.post("/v1/metadata",["cs_dashboard_help_email", "cs_dashboard_help_phone"], headers)
    }

    loadHelpInfo().then(response => {

      const email = response.data.find(x => x.key === "cs_dashboard_help_email").value;
      const phone = response.data.find(x => x.key === "cs_dashboard_help_phone").value;

      setData({"email": email, "phone": phone});

    }).catch(error => {
      // Nothing at the moment
    }).finally(() => {
      setLoading(false);
    });

  }, []);

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <h1 className='page-header fs-4'><FormattedMessage id="help.title" /></h1>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="6">
            <h2 className='section-title fs-5'><FormattedMessage id="help.subtitle" /></h2>
            <div className='section-content'>
              <p><FormattedMessage id="help.contact" /></p>

              { loading ? 
                <>
                  <div className='skeleton skeleton-text' style={{maxWidth: "300px"}}></div>
                  <div className='skeleton skeleton-text' style={{maxWidth: "300px"}}></div>
                </> : 
                 <ul>
                  <li>
                    <i className="fa fa-envelope me-2" aria-hidden="true"/>
                    <a className='text-decoration-none' href={`mailto:${ data?.email ? data.email : "hector.valsamidis@zerocopy.be" }`}>{ data?.email ? data.email : "hector.valsamidis@zerocopy.be" }</a>
                  </li>
                  <li>
                    <i className="fa fa-phone me-2" aria-hidden="true" />
                    <a className='text-decoration-none' href={`tel:${ data?.phone ? data.phone : "+32489468204" }`}>{ data?.phone ? data.phone : "+32 489 46 82 04" }</a>
                  </li>
                </ul>
                }
            </div>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}