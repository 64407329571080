import React, { Fragment } from "react";
import { FormattedNumber } from "react-intl";

const CurrencyOutput = ({value, currencySymbol, minimumFractionDigits = 2, maximumFractionDigits = 5}) => {

  return (
    <Fragment>
      <FormattedNumber value={value} minimumFractionDigits={minimumFractionDigits} maximumFractionDigits={maximumFractionDigits} />
      &nbsp;
      {currencySymbol}
    </Fragment>
  );
};

export default CurrencyOutput;