import { useAuth } from "../hooks/useAuth";

const Secured = ({children}) => {

  const { auth } = useAuth();

  if (auth?.user?.type === "ADMIN") {
    return children;
  }

  return null;
}

export default Secured;