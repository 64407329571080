import React, { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMatch } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import LoginService from "../../login/services/LoginService";
import { useAuth } from "../hooks/useAuth";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { LogoWhite } from "./icons/LogoWhite";
import { LogoWhiteSm } from "./icons/LogoWhiteSm";
import { LanguageSelector } from "./LanguageSelector";

export const MainMenu = (props) => {

  const [collapsed, setCollapsed] = useLocalStorage("mainMenuCollapsed", false);
  const { auth, logout } = useAuth();

  const handleCollapse = (e) => {
    e.preventDefault();
    setCollapsed(!collapsed);
  }

  const handleLogout = (e) => {
    e.preventDefault();

    LoginService.logout(auth.token).then(data => {
      logout();
    }).catch(error => {
      console.log(error);
    });
  }

  const logoSm = (
    <LogoWhiteSm height="30px"/>
  );

  return (
    <div className='d-flex flex-column flex-shrink-0 p-2 bg-zc'>
      <div role="button" className={`text-decoration-none text-center${collapsed ? "": " text-md-start"}`} onClick={handleCollapse}>
        {collapsed ? logoSm : <Fragment><span className="d-none d-md-inline"><LogoWhite /></span><span className="d-inline d-md-none">{logoSm}</span></Fragment>}
      </div>
      <hr className="text-white"/>
      <Nav pills vertical className="mb-auto" >
        <MenuItem collapsed={collapsed} textKey="home" path="/" icon="fa-home" />
        <MenuItem collapsed={collapsed} textKey="pricing.title" path="/pricing" icon="fa-dollar-sign ms-0 ms-sm-1" />
        <MenuItem collapsed={collapsed} textKey="invoiceInfo.title" path="/invoice-info" icon="fa-info-circle" />
        <MenuItem collapsed={collapsed} textKey="help.title" path="/help" icon="fa-life-ring" />
        <MenuItem collapsed={collapsed} textKey="userSettings.title" path="/settings" icon="fa-user-cog" />
      </Nav>
      <hr className="text-white"/>
      <a href="#logout" onClick={handleLogout} className={`text-decoration-none text-white px-3 py-2 text-center${collapsed ? "": " text-sm-start"}`}>
        <i className="fas fa-sign-out-alt"></i>
        {collapsed ? null:<span className="d-none d-md-inline ms-2"><FormattedMessage id="buttons.logout" /></span>}
      </a>
      <LanguageSelector collapsed={collapsed} />
    </div>
  );
}

const MenuItem = ({path, textKey, collapsed, icon}) => {
  const match = useMatch(path);
  const intl = useIntl();
  
  return (
    <NavItem>
      <NavLink href={path} className={`text-center${collapsed ? "": " text-sm-start"}${match !== null?"":" text-white"}`} active={match !== null} title={intl.formatMessage({id: textKey})}>
        <i className={`fas ${icon}`}/>
        {collapsed? null: <span className="d-none d-md-inline ms-2"><FormattedMessage id={textKey} /></span>}
      </NavLink>
    </NavItem>
  );
};