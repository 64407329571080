import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../../images/logo-white.svg';

export const LogoWhite = (props) => {
  return (
    <img src={logo}
          alt={props.alt}
          title={props.title}
          width={props.width}
          height={props.height}
          className={props.className}
        />
  );
};

LogoWhite.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
};

LogoWhite.defaultProps = {
  alt: "Zerocopy"
};
