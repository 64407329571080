import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { resolvePath } from "../functions";
import { DEFAULT_MAX_LENGTH } from "../validations";

export const InputErrors = ({fieldName, errors}) => {

  const error = resolvePath(errors, fieldName, undefined);

  if (!error) {
    return null;
  }

  return (
    <Fragment>
      {error.type === "required" && <p className="text-danger small mb-0"><FormattedMessage id="validations.field.required" /></p>}
      {error.type === "maxLength" && <p className="text-danger small mb-0"><FormattedMessage id="validations.field.maxLength" values={{length: DEFAULT_MAX_LENGTH}} /></p>}
      {error.type === "manual" && <p className="text-danger small mb-0">{error.message}</p>}
    </Fragment>
  );
}