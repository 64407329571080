import React, { Fragment, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Collapse, Row } from "reactstrap";

const Section = ({collapsible = false, headerKey, children}) => {
  const [collapsed, setCollapsed] = useState(collapsible);

  const toggle = useCallback(() => {
    setCollapsed((prevState) => !prevState);
  }, []);

  return (
    <Fragment>
      <Row className={collapsed ? "mb-3" : null}>
        <Col>
            <h2 className="section-title fs-5">
              <div className="d-flex justify-content-between">
                <span>{headerKey && <FormattedMessage id={headerKey} />}</span>
                {collapsible ? <i className={`fas ${collapsed ? "fa-plus" : "fa-minus"} align-self-center`} role="button" onClick={toggle}></i> : null}
              </div>
            </h2>
        </Col>
      </Row>
      <Collapse isOpen={!collapsible || !collapsed}>
        <div className="section-content">
          {children}
        </div>
     </Collapse>
    </Fragment>
  );
};

export default Section;