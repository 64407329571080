import React from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Button, Form, Label } from "reactstrap";
import { InputErrors } from "../../commons/components/InputErrors";
import { PasswordInput } from "../../commons/components/PasswordInput";
import { handleApiErrors } from "../../commons/functions";

import { DEFAULT_MAX_LENGTH } from "../../commons/validations";
import UserService from "../services/UserService";

export const ChangePasswordForm = (props) => {

  const intl = useIntl();
  const { register, handleSubmit, setError, formState: { submitting, errors } } = useForm({
    mode: "onSubmit"
  });
  const { ref: refOldPassword, ...registerOldPassword } = register('oldPassword', { required: true, maxLength: DEFAULT_MAX_LENGTH });
  const { ref: refNewPassword, ...registerNewPassword } = register('newPassword', { required: true, maxLength: DEFAULT_MAX_LENGTH });
  const { ref: refNewPasswordRepeat, ...registerNewPasswordRepeat } = register('newPasswordRepeat', { required: true, maxLength: DEFAULT_MAX_LENGTH });

  const updateMutation = useMutation((data) => UserService.changePassword(data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "userSettings.changePassword.msg.success"}));
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "userSettings.changePassword.errors.general"))
  });

  const onSubmit = (data) => {
    let error = false;

    // Custom validations here
    if (!error && data.newPassword !== data.newPasswordRepeat) {
      error = true;
      setError(
        "newPassword",
        {
            "type": "manual",
            "message": intl.formatMessage({"id": "userSettings.changePassword.errors.newPasswordNotEquals"})
        }
      );
    }

    if (!error && data.oldPassword === data.newPassword) {
      error = true;
      setError(
        "newPassword",
        {
            "type": "manual",
            "message": intl.formatMessage({"id": "userSettings.changePassword.errors.equalsToOldPassword"})
        }
      );
    }

    if (!error) {
      updateMutation.mutate(data);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h2 className='section-title fs-5'>
          <FormattedMessage id="userSettings.changePassword.title" />
        </h2>
      </div>
      <div className="section-content">
        <div>
          <Label for='oldPassword'>
            <FormattedMessage id="userSettings.changePassword.field.oldPassword" />
          </Label>
          <PasswordInput id="oldPassword" innerRef={refOldPassword} {...registerOldPassword} />
          <InputErrors fieldName="oldPassword" errors={errors} />
        </div>
        <div>
          <Label for='newPassword'>
            <FormattedMessage id="userSettings.changePassword.field.newPassword" />
          </Label>
          <PasswordInput id="newPassword" innerRef={refNewPassword} {...registerNewPassword} />
          <InputErrors fieldName="newPassword" errors={errors} />
        </div>
        <div>
          <Label for='newPasswordRepeat'>
            <FormattedMessage id="userSettings.changePassword.field.repeatNewPassword" />
          </Label>
          <PasswordInput id="newPasswordRepeat" innerRef={refNewPasswordRepeat} {...registerNewPasswordRepeat} />
          <InputErrors fieldName="newPasswordRepeat" errors={errors} />
        </div>
        <div className='mt-2'>
          <Button color='primary' disabled={submitting}>
            <FormattedMessage id="userSettings.changePassword.buttons.change" />
          </Button>
        </div>
      </div>
    </Form>
  );
};