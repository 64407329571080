import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Page } from "./Page";

const SecuredPage = ({children}) => {

  const { auth } = useAuth();

  if (auth?.user?.type === "ADMIN") {
    return <Page>{children}</Page>;
  }

  return <Navigate to="/" />;
}

export default SecuredPage;