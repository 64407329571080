
import localeDataEn from './en.json';
import localeDataFr from './fr.json';
import localeDataNl from './nl.json';

export default {
  "en": localeDataEn,
  "fr": localeDataFr,
  "nl": localeDataNl
}
