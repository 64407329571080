import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { Button, Form, Input, Spinner } from "reactstrap";
import { InputErrors } from "../../commons/components/InputErrors";
import { DEFAULT_MAX_LENGTH } from "../../commons/validations";
import UserService from "../services/UserService";

export const ForgotPasswordForm = (props) => {

  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { submitting, errors } } = useForm({
    mode: "onSubmit"
  });
  const { ref: refEmail, ...registerEmail } = register('email', { required: true, maxLength: DEFAULT_MAX_LENGTH });

  const onSubmit = (data) => {
    
    if (submitting || loading) {
      return;
    }

    setLoading(true);

    UserService.forgotPassword(data.email)
      .then(response => {
        toast.success(intl.formatMessage({id: "forgotPassword.message.success"}));
      }).catch(error => {
        toast.error(intl.formatMessage({id: "forgotPassword.message.error"}));
      }).finally(() => {
        setLoading(false);
      });
    
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="section-content">
        <div>
          <FormattedMessage id="forgotPassword.text" />
        </div>
        <div className="mt-2">
          <Input id="email" autoFocus innerRef={refEmail} {...registerEmail} placeholder={intl.formatMessage({id: "forgotPassword.field.email.placeholder"})} />
          <InputErrors fieldName="email" errors={errors} />
        </div>
        <div className='mt-2'>
          <Button color='primary' disabled={submitting || loading} block>
            <FormattedMessage id="forgotPassword.buttons.reset" />
            {submitting || loading?<Spinner size="sm" className="ms-2"/>:null}
          </Button>
        </div>
      </div>
    </Form>
  );
};