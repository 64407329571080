import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

const BackButton = (props) => {

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  }

  return (
      <Button onClick={handleBack}>
        <FormattedMessage id="buttons.back" />
      </Button>
  );
};

export default BackButton;