import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Secured from '../../commons/components/Secured';
import CustomSpinner from '../../commons/components/Spinner';
import { currencySymbol, handleApiErrors, handleErrors } from '../../commons/functions';
import PricingLevelService from '../services/PricingLevelService';
import { PricingLevelForm } from './PricingLevelForm';
import CurrencyOutput from '../../commons/components/CurrencyOutput';

import './PricingLevelList.css';
import DataTable from '../../commons/components/DataTable';
import WarningMessage from '../../commons/components/WarningMessage';

const MAX_VALUE = 999999999;

const dataTableColumns = [
  {
    headerKey: "pricing.pricingLevel.field.level",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => row.level
  },
  {
    headerKey: "pricing.pricingLevel.field.minimum",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => <FormattedNumber value={row.minimum} />
  },
  {
    headerKey: "pricing.pricingLevel.field.maximum",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => MAX_VALUE === row?.maximum ? <Fragment>&infin;</Fragment>: <FormattedNumber value={row.maximum} />
  },
  {
    headerKey: "pricing.pricingLevel.field.price",
    headerClassName: "text-end",
    className: "text-end",
    selector: row => <CurrencyOutput value={row.price} currencySymbol={currencySymbol(row.currency)} />
  }
];

export const PricingLevelList = ({configId, isEditable}) => {

  const noConfigSelected = useMemo(() => configId === null || configId === undefined, [configId]);
  const intl = useIntl();
  const [list, setList] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openTecInfo, setOpenTecInfo] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const queryClient = useQueryClient();

  const result = useQuery(["pricingLevels", configId], () => PricingLevelService.list(configId), {
    onSuccess: (response) => setList(response.data),
    enabled: !noConfigSelected
  });

  const addMutation = useMutation(([configId, data, setError]) => PricingLevelService.create(configId, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "pricing.pricingLevel.msg.create"}));
      queryClient.invalidateQueries("pricingLevels");
      toggleForm();
    },
    onError: (error, [configId, data, setError], context) => {
      handleErrors(error, "pricing.pricingLevel.error.create", setError, toast, intl);
    }
  });

  const deleteMutation = useMutation(([configId, level]) => PricingLevelService.delete(configId, level), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "pricing.pricingLevel.msg.delete"}));
      queryClient.invalidateQueries("pricingLevels");
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "pricing.pricingLevel.error.delete")),
    onSettled: (data, error, vars, context) => toggleDelete()
  });

  const updateMutation = useMutation(([configId, level, data, setError]) => PricingLevelService.update(configId, level, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "pricing.pricingLevel.msg.update"}));
      queryClient.invalidateQueries("pricingLevels");
      toggleForm();
    },
    onError: (error, [configId, level, data, setError], context) => {
      handleErrors(error, "pricing.pricingLevel.error.update", setError, toast, intl);
    }
  });

  const handleDelete = () => {
    deleteMutation.mutate([selectedRow.pricingId, selectedRow.level]);
  };

  const handleConfirmDelete = (row) => {
    setSelectedRow(row);
    toggleDelete();
  };

  const handleUpdate = (row) => {
    setSelectedRow(row);
    toggleForm();
  }

  const handleSave = (data, setError) => {
    if (selectedRow) {
      updateMutation.mutate([selectedRow.pricingId, selectedRow.level, data, setError]);
    } else {
      addMutation.mutate([configId, data, setError]);
    }
  };

  useEffect(() => {
    if (!openForm) {
      setSelectedRow(null);
    }
  }, [openForm]);

  useEffect(() => {
    if (!openDeleteConfirm) {
      setSelectedRow(null);
    }
  }, [openDeleteConfirm]);

  const toggleForm = () => {
    setOpenForm(!openForm);
  };

  const toggleDelete = () => {
    setOpenDeleteConfirm(oldValue => !oldValue);
  };

  const toggleTecInfo = () => {
    setOpenTecInfo(oldValue => !oldValue);
  };

  let content = null;

  if (noConfigSelected) {
    content = (
      <FormattedMessage id="pricing.pricingLevel.selectDefinition" />
    );
  } else if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="pricing.pricingLevel.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="pricing.pricingLevel.error.loading" />
    );
  } else if (list.length === 0) {
    content = (
      <FormattedMessage id="pricing.pricingLevel.emptyList" />
    );
  } else {
    content = (
      <Fragment>
        <DataTable data={list} columns={dataTableColumns} responsive bordered className='pricing-level-table' actionsHeaderStyle={{width: "60px"}} securedActions={true} renderRowActions={(row => {

          if (!isEditable) {
            return (
              <i className="fas fa-info-circle me-2" role="button" onClick={toggleTecInfo}></i>
            );
          }

          return (
            <Fragment>
              <i className="fas fa-edit me-2" role="button" onClick={() => handleUpdate(row)}></i>
              {list?.length !== 1 ? <i className="fas fa-trash-alt" role="button" onClick={() => handleConfirmDelete(row)}></i> : null }
            </Fragment>
          );
        })} />
      </Fragment>
    );
  }

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h2 className='section-title fs-5 flex-grow-1'><FormattedMessage id="pricing.pricingLevel.title" /></h2>
        <Secured>
          <Button className='ms-2' size='sm' disabled={noConfigSelected || !isEditable || result.isLoading} onClick={toggleForm} title={intl.formatMessage({id: "pricing.pricingLevel.buttons.add"})}>
            <i className='fas fa-plus me-0 me-md-2' />
            <span className='d-none d-md-inline'>
              <FormattedMessage id="pricing.pricingLevel.buttons.add" />
            </span>
          </Button>
        </Secured>
      </div>
      <div className='section-content'>
        {content}
      </div>
      { openDeleteConfirm ?  <DeletePricingLevelModal open={openDeleteConfirm} toggle={toggleDelete} onConfirm={handleDelete} disabled={deleteMutation.isLoading} /> : null }
      { openForm ? <PricingLevelFormModal open={openForm} toggle={toggleForm} data={selectedRow ? selectedRow : {pricingId: configId}} onSave={handleSave}
          disabled={addMutation.isLoading || updateMutation.isLoading}
          headerKey={selectedRow ? "pricing.pricingLevel.edit.title": "pricing.pricingLevel.add.title"}/> : null }
      {openTecInfo ? <TechnicalInfoModal open={openTecInfo} toggle={toggleTecInfo} />: null}
    </div>
  );
}

const DeletePricingLevelModal = ({open, toggle, onConfirm, disabled = false}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="confirm" />
      </ModalHeader>
      <ModalBody>
        <WarningMessage messageKey="pricing.pricingLevel.delete.warning" />
        <FormattedMessage id="pricing.pricingLevel.delete" />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button color='danger' onClick={onConfirm} disabled={disabled}>
            <FormattedMessage id="pricing.pricingLevel.buttons.delete" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

const PricingLevelFormModal = ({open, toggle, headerKey, data, onSave, disabled = false}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id={headerKey} />
      </ModalHeader>
      <ModalBody>
        {data?.level ? <WarningMessage messageKey="pricing.pricingLevel.msg.edit.warning" /> : null}
        <PricingLevelForm id="pricing-level-form" onSave={onSave} onSaveSuccess={toggle} max={MAX_VALUE} defaultValues={data} disabled={disabled} />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button color='primary' disabled={disabled} type="submit" form="pricing-level-form">
            <FormattedMessage id="pricing.pricingLevel.buttons.save" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}


const TechnicalInfoModal = ({open, toggle}) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="info" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="pricing.pricingLevel.msg.technicalContact" />
      </ModalBody>
    </Modal>
  );
}