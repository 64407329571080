import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "reactstrap";
import Section from "../../commons/components/Section";
import CustomSpinner from "../../commons/components/Spinner";
import OpeningHoursReadOnly from "./OpeningHoursReadOnly";

export const CopyShpopDetail = ({item}) => {

  if (Object.keys(item).length === 0) {
    return (
      <CustomSpinner messageKey="copyShop.detail.loading" />
    );
  }

  let address =  item.address + " - " + item.cityName;
  if (item.postalCode) {
    address =item.address + " - " + item.postalCode + " " + item.cityName
  }

  return (
    <Section headerKey="copyShop.detail.title" collapsible={true}>
      <Row>
        <Col xs="12" md="6" lg="4">
          <div className="d-flex flex-column" style={{gap: "0.5rem"}}>
            <PlainField labelKey="copyShop.field.address" value={address} />
            <PlainField labelKey="copyShop.field.email" value={item.email} />
            <PlainField labelKey="copyShop.field.phoneNumber" value={item.phoneNumber} />
            <PlainField labelKey="copyShop.field.contactPerson" value={item.contactPerson} />
            <PlainField labelKey="copyShop.field.contactEmail" value={item.contactEmail} />
            <PlainField labelKey="copyShop.field.phoneNumber2" value={item.phoneNumber2} />
            <PlainField labelKey="copyShop.field.website" value={item.website} />
          </div>
        </Col>
        <Col xs="12" md="6" lg="4" className="mt-2 mt-md-0">
          <div className="d-flex flex-column" style={{gap: "0.5rem"}}>
            <PlainField labelKey="copyShop.field.vat" value={item.vat} />
            <PlainField labelKey="copyShop.field.bic" value={item.bic} />
            <PlainField labelKey="copyShop.field.iban" value={item.iban} />
            <PlainField labelKey="copyShop.field.license" value={item.license} />
          </div>
        </Col>
        <Col xs="12" md="6" lg="4" className="mt-2 mt-lg-0">
          <div className="d-flex flex-column" style={{gap: "0.5rem"}}>
            <strong><FormattedMessage id="copyShop.field.openingHours" /></strong>
            <OpeningHoursReadOnly data={item.businessDays} />
          </div>
        </Col>
      </Row> 
    </Section>
  );

};

export const PlainField = ({labelKey, value, valueClassName, valueStyle}) => {

  return (
    <div className="d-flex">
      <strong><FormattedMessage id={labelKey} />:</strong>
      <span className={`ms-2${valueClassName ? " " + valueClassName : ""}`} style={valueStyle}>{value}</span>
    </div>
  );
};