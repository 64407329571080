import React, { Fragment, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useQuery } from "react-query";
import { Table } from "reactstrap";
import ListPagination from "../../commons/components/ListPagination";
import CustomSpinner from "../../commons/components/Spinner";
import PrintService from "../services/PrintService";
import Moment from "react-moment";

const pageSize = 10;

export const CopyShopDayPrints = ({id, day, small = true}) => {
  
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState({total: 0, list: []});

  const result = useQuery(["prints/today", id, day, currentPage, pageSize], () => PrintService.list(id, currentPage, pageSize, {startDate: day, endDate: day}), {
    enabled: id !== undefined,
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  let content = null;

  if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="copyShop.today.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="copyShop.today.error" />
    );
  } else if (page.total === 0) {
    content = (
      <FormattedMessage id="copyShop.today.empty" />
    );
  } else {

    const items = page.list.map((item, index) => 
      <PrintItem key={index} item={item} />
    );
  
    let pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

    content = (
      <Fragment>
        <Table responsive bordered size={small ? "sm" : null} style={small ? {fontSize: ".9rem"} : null}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="copyShop.today.field.created" />
              </th>
              <th className="text-end">
                <FormattedMessage id="copyShop.today.field.pages" />
              </th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>
        {pagination}
      </Fragment>
    );
  }

  return (
    <Fragment>
    {content}
    </Fragment>
  );
};

const PrintItem = ({item}) => {
  return (
    <tr>
      <td><Moment parse="DD/MM/yyyy HH:mm:ss" format="DD/MM/yyyy HH:mm:ss" utc local>{item.created}</Moment></td>
      <td className="text-end"><FormattedNumber value={item.pages} /></td>
    </tr>
  );
};