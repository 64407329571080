import { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import CustomSpinner from "../../commons/components/Spinner";
import { handleApiErrors } from "../../commons/functions";
import CopyShopService from "../services/CopyShopService";
import CopyShopUserModal from "./CopyShopUserModal";

const CopyShopLinkedUsers = ({identifier}) => {

  const intl = useIntl();
  const [list, setList] = useState([]);

  const result = useQuery(["linkedUsers", identifier], () => CopyShopService.linkedUsers(identifier), {
    onSuccess: (response) => setList(response.data)
  });

  return (
    <Fragment>
      
        <div className="mt-3">
            <div>
              <div className="d-flex justify-content-between">
                <h2 className="section-title flex-grow-1 me-2"><FormattedMessage id="copyShop.assocUser.title" /></h2>
                <CopyShopUserModal identifier={identifier} disabled={result.isLoading} />
              </div>
            </div>
            <div className="section-content">

            { result.isLoading ? <div className="mt-2"><CustomSpinner messageKey="copyShop.assocUser.loading" /></div> : null }
            { !result.isLoading && list?.length === 0 ? <div className="mt-2"><FormattedMessage id="copyShop.assocUser.empty" /></div> : null }
            { !result.isLoading && list?.length !== 0 ? <Table responsive striped hover bordered>
                <thead>
                  <tr>
                    <th><FormattedMessage id="copyShop.assocUser.field.type" /></th>
                    <th><FormattedMessage id="copyShop.assocUser.field.email" /></th>
                    <th><FormattedMessage id="copyShop.assocUser.field.name" /></th>
                    <th style={{width: "60px"}}></th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, index) => {
                    return (
                      <LinkedUserItem key={index} item={item} copyShop={identifier} intl={intl} />
                    );

                  })}
                </tbody>
              </Table> : null }
            </div>
      </div>
    </Fragment>
  );

}

export default CopyShopLinkedUsers;

const LinkedUserItem = ({copyShop, item, intl}) => {

  const [openDelete, setOpenDelete] = useState(false);
  
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(([identifier, userId]) => CopyShopService.deleteLink(identifier, userId), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "copyShop.assocUser.msg.delete"}));
      queryClient.invalidateQueries("linkedUsers");
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "copyShop.assocUser.error.delete")),
    onSettled: () => {
      toggleDelete();
    }
  });

  const handleConfirmDelete = () => {
    deleteMutation.mutate([copyShop, item.id]);
  };

  const toggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  return (
    <tr>
      <td><FormattedMessage id={"copyShop.assocUser.type." + item.type} /></td>
      <td>{item.email}</td>
      <td>{item.fullName}</td>
      <td className="text-center">
        <i className="fas fa-trash-alt" role="button" onClick={toggleDelete}></i>
        {openDelete ? <DeleteLinkModal open={openDelete} toggle={toggleDelete} onConfirm={handleConfirmDelete} disabled={deleteMutation.isLoading} /> : null }
      </td>
    </tr>
  );

};

const DeleteLinkModal = ({open, toggle, onConfirm, disabled = false}) => {

  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="confirm" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="copyShop.assocUser.delete" />
      </ModalBody>
      <ModalFooter>
        <div className='d-flex justify-content-end'>
          <Button color='danger' onClick={onConfirm} disabled={disabled}>
            <FormattedMessage id="copyShop.assocUser.buttons.delete" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};