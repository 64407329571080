import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import Section from "../../commons/components/Section";
import { CopyShopDayPrints } from "./CopyShopDayPrints";


export const CopyShopTodayPrints = ({id}) => {
  
  const today = moment().format("YYYY-MM-DD");

  return (
    <Section headerKey="copyShop.today.title">
      <Row>
        <Col>
          <CopyShopDayPrints id={id} day={today} />
        </Col>
      </Row>
    </Section>
  );
};