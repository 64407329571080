import moment from "moment";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import { LanguageContext } from "../../commons/context/LanguageContext";
import { InputErrors } from "../../commons/components/InputErrors";
import { FormattedMessage } from "react-intl";

const OpeningHours = ({onCancel, onAccept, value}) => {
  
  const { language } = useContext(LanguageContext);

  const { register, control, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    mode: "onSubmit",
    defaultValues: value
  });
  
  moment.updateLocale(language, {
    week: {
      dow: 1
    }
  });

  const weekdays = moment.weekdays(true);

  const handleAccept = (data) => {
    onAccept(data);
  }

  return (
    <Form onSubmit={handleSubmit(handleAccept)}>
      <div>
        <div className="d-flex flex-column" style={{gap: "1rem"}}>
          {weekdays.map((d, index) => {
            return (
              <Day key={index} dayIndex={index} day={d} control={control} register={register} errors={errors} setValue={setValue} getValues={getValues} value={value} />
            );
          })}
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Button color="primary">
            <FormattedMessage id="buttons.save" />
          </Button>
          <Button className="ms-2" onClick={onCancel}>
            <FormattedMessage id="buttons.cancel" />
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default OpeningHours;

const Day = ({dayIndex, day, register, setValue, getValues, errors, value}) => {

  const [split, setSplit] = useState(value?.businessDays?.[dayIndex]?.split);
  const [open, setOpen] = useState(value?.businessDays?.[dayIndex]?.open);

  const openRequiredVal = (value) => {
    if (!value && getValues(`businessDays[${dayIndex}].open`) === true) {
      console.log("error", value);
      return "Required";
    }
    return true;
  };

  const openAndSplitRequiredVal = (value) => {
    if (!value && getValues(`businessDays[${dayIndex}].open`) === true && getValues(`businessDays[${dayIndex}].split`) === true) {
      return "Required";
    }
    return true;
  };

  const { ref: refOpen, ...registerOpen } = register(`businessDays[${dayIndex}].open`);
  const { ref: refSplit, ...registerSplit } = register(`businessDays[${dayIndex}].split`);
  const { ref: refTimeFrom, ...registerTimeFrom } = register(`businessDays[${dayIndex}].from`, {validate: {required: openRequiredVal}});
  const { ref: refTimeTo, ...registerTimeTo } = register(`businessDays[${dayIndex}].to`, {validate: {required: openRequiredVal}});
  const { ref: refTimeSplitFrom, ...registerTimeSplitFrom } = register(`businessDays[${dayIndex}].fromSplit`, {validate: {required: openAndSplitRequiredVal}});
  const { ref: refTimeSplitTo, ...registerTimeSplitTo } = register(`businessDays[${dayIndex}].toSplit`, {validate: {required: openAndSplitRequiredVal}});

  const handleSplit = () => {
    setValue(`businessDays[${dayIndex}].split`, !split);
    setValue(`businessDays[${dayIndex}].fromSplit`, null);
    setValue(`businessDays[${dayIndex}].toSplit`, null);
    setSplit(!split);
  }

  const handleOpen = (e) => {
    setValue(`businessDays[${dayIndex}].open`, !open);
    setOpen(!open);
    if (open) {
      setValue(`businessDays[${dayIndex}].from`, null);
      setValue(`businessDays[${dayIndex}].to`, null);
      setValue(`businessDays[${dayIndex}].fromSplit`, null);
      setValue(`businessDays[${dayIndex}].toSplit`, null);
      setValue(`businessDays[${dayIndex}].split`, null);
    }
  }

  return (
    <div className="d-flex">
      <FormGroup switch className="align-self-center" style={{width: "125px"}}>
        <Input type="switch" role="switch" onClick={handleOpen} innerRef={refOpen} {...registerOpen} />
        <Label className="text-capitalize" onClick={handleOpen} check>{day}</Label>
      </FormGroup>
      { open ? 
      <div className="d-flex">
        <div>
          <div className="d-flex">
            <div className="ms-2 align-self-center"><FormattedMessage id="copyShop.openingHours.text.from" /></div>
            <div className="ms-2">
              <Input type="time" bsSize="sm" innerRef={refTimeFrom} {...registerTimeFrom} />
              <InputErrors fieldName={`${dayIndex}.timeFrom`} errors={errors} />
            </div>
            <div className="ms-2 align-self-center"><FormattedMessage id="copyShop.openingHours.text.to" /></div>
            <div className="ms-2">
              <Input type="time" bsSize="sm" innerRef={refTimeTo} {...registerTimeTo} />
              <InputErrors fieldName={`${dayIndex}.timeTo`} errors={errors} />
            </div>
          </div>
          { split ? 
            <div className="d-flex mt-2">
              <div className="ms-2 align-self-center" style={{width: "35px"}}>
                <FormattedMessage id="copyShop.openingHours.text.and" />
              </div>
              <div className="ms-2">
                <Input type="time" bsSize="sm" innerRef={refTimeSplitFrom} {...registerTimeSplitFrom} />
                <InputErrors fieldName={`${dayIndex}.timeSplitFrom`} errors={errors} />
              </div>
              <div className="ms-2 align-self-center">
                <FormattedMessage id="copyShop.openingHours.text.to" />
              </div>
              <div className="ms-2">
                <Input type="time" bsSize="sm" innerRef={refTimeSplitTo} {...registerTimeSplitTo} />
                <InputErrors fieldName={`${dayIndex}.timeSplitTo`} errors={errors} />
              </div>
            </div> : null }
        </div>
        <div className="align-self-end">
          <Button id={`split-button-${dayIndex}`} className="ms-2" size="sm" onClick={handleSplit}>
            {split ? <i className='fas fa-times' /> : <i className='fas fa-plus' /> }
          </Button>
          <UncontrolledTooltip placement="top" target={`split-button-${dayIndex}`}>
            <FormattedMessage id={split ? "copyShop.openingHours.buttons.cancelSplit" : "copyShop.openingHours.buttons.split"} />
          </UncontrolledTooltip>
        </div>
      </div> : <div className="ms-2"><FormattedMessage id="copyShop.openingHours.text.closed" /></div> }
    </div>
  );

};