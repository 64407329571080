import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { MapContainer, Marker, TileLayer, useMapEvent } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { InputErrors } from "../../commons/components/InputErrors";
import CustomSpinner from "../../commons/components/Spinner";
import { DEFAULT_MAX_LENGTH } from "../../commons/validations";
import CityService from "../services/CityService";
import CopyShopService from "../services/CopyShopService";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { handleErrors, randomText } from "../../commons/functions";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CopyShopLinkedUsers from "./CopyShopLinkedUsers";
import OpeningHours from "./OpeningHours";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize:    [25, 41],
    iconAnchor:  [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize:  [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const brusselsPosition = [50.859017, 4.343561];

const defaultOpeningHours = [
  {day: 0, open: true},
  {day: 1, open: true},
  {day: 2, open: true},
  {day: 3, open: true},
  {day: 4, open: true},
  {day: 5, open: true},
  {day: 6, open: true}
];

export const CopyShopForm = ({id}) => {

  const intl = useIntl();
  const navigate = useNavigate();
  
  const [openingHours, setOpeningHours] = useState(defaultOpeningHours);
  const [modalOpeningHours, setModalOpeningHours] = useState(false);
  const [item, setItem] = useState({currency: "EUR", businessDays: openingHours, active: true});
  const [cities, setCities] = useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  const [openMap, setOpenMap] = useState(false);
  const [position, setPosition] = useState(brusselsPosition);
  
  const { control, register, reset, handleSubmit, setValue, setFocus, setError, formState: { submitting, errors } } = useForm({
    mode: "onSubmit",
    defaultValues: item
  });

  register("businessDays", { required: true });
  
  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  const selectedCity = useWatch({ control: control, name: 'cityId' });

  const queryClient = useQueryClient();
  const result = useQuery(["copyshop", id], () => CopyShopService.get(id), {
    enabled: id !== undefined,
    onSuccess: (response) => setItem(response.data)
  });

  const addMutation = useMutation((data) => CopyShopService.create(data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "copyShop.msg.create"}));
      queryClient.invalidateQueries("copyShops");
      navigate("/copy-shops/" + response.data.id);
    },
    onError: (error, vars, context) => {
      handleErrors(error, "copyShop.error.create", setError, toast, intl);
    }
  });

  const updateMutation = useMutation(([identifier, data]) => CopyShopService.update(identifier, data), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "copyShop.msg.update"}));
      queryClient.invalidateQueries("copyShops");
    },
    onError: (error, vars, context) => {
      handleErrors(error, "copyShop.error.update", setError, toast, intl);
    }
  });

  const citiesResult = useQuery("cities", () => CityService.list(), {
    onSuccess: (response) => setCities(response.data)
  });
  
  const loadPostalCodes = useCallback(() => {

    const city = cities.find(c => c.id === parseInt(selectedCity));
    if (city) {
      setPostalCodes(city.postalCodes);
    }

  }, [cities, selectedCity]);

  useEffect(() => {
    reset(item);

    if (item.coordinates) {
      setPosition(item.coordinates.split(", "));
    }
    if (item?.businessDays.length !== 0) {
      setOpeningHours(item?.businessDays);
    }
    
  }, [item]);

  useEffect(() => {

   loadPostalCodes();

  }, [loadPostalCodes]);

  const onSubmit = (data) => {
    if (id) {
      updateMutation.mutate([id, data]);
    } else {
      addMutation.mutate(data);
    }

  };

  const onCancel = (e) => {
    e.preventDefault();

    navigate("/");
  };

  const onRandomLicense = (e) => {
    e.preventDefault();

    setValue("license", randomText(16));
  };

  const toggleMap = (e) => {
    setOpenMap(!openMap);
  };

  const onSelectCoordinates = (position) => {
    setValue("coordinates", position[0] + ", " + position[1]);
    setPosition(position);
    setOpenMap(false);
  };

  const toggleOpeningHours = (e) => {
    e.preventDefault();
    setModalOpeningHours(!modalOpeningHours);
  }

  const handleAcceptOpeningHours = (data) => {
    setModalOpeningHours(false);

    data?.businessDays.forEach((dataItem, index) => {
      if (!dataItem.day) {
        dataItem.day = index;
      }
    });

    setValue("businessDays", data?.businessDays);
    setOpeningHours(data?.businessDays);
  }

  if (result.isLoading || addMutation.isLoading || updateMutation.isLoading || citiesResult.isLoading) {
    return (
      <CustomSpinner messageKey="copyShop.edit.loading" />
    );
  }

  const citiesOptions = cities.map((city, index) => <option key={index} value={city.id}>{city.name}</option>);
  const postalCodesOptions = postalCodes.map((postalCode, index) => <option key={index} value={postalCode}>{postalCode}</option>);

  let newUser = null;

  if (!id) {
    newUser = (
      <Row>
        <Col>
          <Label>
            <input type="checkbox" className="me-2" {...register("newUser")}/>
            <FormattedMessage id="copyShop.field.newUser" />
          </Label>
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {newUser}
        
        <Row>
          <Col xs="12" md="4">
            <CopyShopField field="name" options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
          <Col xs="12" md="4">
            <CopyShopField field="email" type="email" options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
          <Col xs="12" md="4">
            <CopyShopField field="phoneNumber" options={{ maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
          
        </Row>

        <Row>
          <Col xs="12" md="4">
            <CopyShopField field="contactPerson" options={{ maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
          <Col xs="12" md="4">
            <CopyShopField field="contactEmail" options={{ maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
          <Col xs="12" md="4">
            <CopyShopField field="phoneNumber2" options={{ maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="4">
            <CopyShopField field="cityId" type="select" options={{ required: true }} errors={errors} register={register}>
              <option></option>{citiesOptions}
            </CopyShopField>
          </Col>
          <Col xs="12" md="4">
            <CopyShopField field="postalCode" options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} list="postalCodesList" />
            <datalist id="postalCodesList">{postalCodesOptions}</datalist>
          </Col>
          <Col xs="12" md="4">
            <CopyShopField field="address" options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="4">
            <CopyShopField field="iban" options={{ maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
          <Col xs="12" md="4">
            <CopyShopField field="bic" options={{ maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
          <Col xs="12" md="4">
            <CopyShopField field="vat" options={{ maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="4">
            <CopyShopField field="currency" type="select" options={{ required: true }} errors={errors} register={register}>
              <option value="EUR">EUR</option>
            </CopyShopField>
          </Col>
          <Col xs="12" md="4">
            <CopyShopAddonField field="license" options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} iconClassName="fas fa-random" onIconClick={onRandomLicense} errors={errors} register={register}/>
          </Col>
          <Col xs="12" md="4">
            <CopyShopAddonField field="coordinates" options={{ required: true, maxLength: DEFAULT_MAX_LENGTH }} iconClassName="fas fa-map-marker-alt" onIconClick={toggleMap} errors={errors} register={register}/>
            <Modal isOpen={openMap} toggle={toggleMap}>
                <ModalBody>
                  <Map onSelect={onSelectCoordinates} sourcePosition={position} zoom={16} />
                </ModalBody>
            </Modal>
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="4">
            <CopyShopField field="website" options={{ maxLength: DEFAULT_MAX_LENGTH }} errors={errors} register={register} />
          </Col>
          <Col xs="6" md="4">
            <div>
              <Label>
                <input type="checkbox" className="me-2" {...register("active")}/>
                <FormattedMessage id="copyShop.field.active" />
              </Label>
            </div>
          </Col>
          <Col xs="6" md="4">
            <Button block onClick={toggleOpeningHours}>
              <FormattedMessage id="copyShop.openingHours.buttons.define" />
            </Button>
            <InputErrors fieldName="businessDays" errors={errors} />
          </Col>
        </Row>
        
        <div className='mt-2 d-flex justify-content-end'>
          <Button color='primary' disabled={submitting} type="submit">
            <FormattedMessage id="copyShop.buttons.save" />
          </Button>
          <Button disabled={submitting} type="button" className="ms-2" onClick={onCancel}>
            <FormattedMessage id="buttons.cancel" />
          </Button>
        </div>
      </Form>
      { id ? <CopyShopLinkedUsers identifier={id} /> : null }
      <Modal isOpen={modalOpeningHours} keyboard={false} centered >
        <ModalHeader>
          <FormattedMessage id="copyShop.openingHours.title" />
        </ModalHeader>
        <ModalBody>
          <OpeningHours onCancel={toggleOpeningHours} onAccept={handleAcceptOpeningHours} value={{businessDays: openingHours}} />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const CopyShopField = ({field, register, errors, options, type = "text", children, ...props}) => {

  const { ref: refField, ...registerField } = register(field, options);

  return (
    <FormGroup>
      <Label for={field}> <FormattedMessage id={`copyShop.field.${field}`} /></Label>
      <Input id={field} name={field} type={type} innerRef={refField} {...registerField} {...props}>{children}</Input>
      <InputErrors fieldName={field} errors={errors} />
    </FormGroup>
  );
  
};

const CopyShopAddonField = ({field, register, errors, options, iconClassName, onIconClick, type = "text", children, ...props}) => {

  const { ref: refField, ...registerField } = register(field, options);

  return (
    <FormGroup>
      <Label for={field}>
        <FormattedMessage id={`copyShop.field.${field}`} />
      </Label>
      <InputGroup>
        <Input id={field} name={field} type={type} innerRef={refField} {...registerField} {...props}>{children}</Input>
        <InputGroupText role="button" onClick={onIconClick}><i className={iconClassName}></i></InputGroupText>
      </InputGroup>
      <InputErrors fieldName={field} errors={errors} />
    </FormGroup>
  );
}

const Map = ({sourcePosition, onSelect, zoom}) => {

  const [position, setPosition] = useState(sourcePosition);

  const handleClick = (e) => {
    setPosition([e.latlng.lat, e.latlng.lng]);
  }

  const handleSelection = (e) => {
    e.preventDefault();
    onSelect(position);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setPosition(sourcePosition);
  };

  return (
    <div style={{width: "100%", height: "500px"}}>
      <MapContainer center={position} zoom={zoom} style={{width: "100%", height: "90%"}} scrollWheelZoom={true} zoomControl={true} doubleClickZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MapClick onClick={handleClick}/>
        <Marker position={position}></Marker>
      </MapContainer>
      <div className="d-flex justify-content-end mt-3" style={{gap: "1rem"}}>
        <Button onClick={handleSelection}>Select</Button>
        <Button onClick={handleReset}>Reset</Button>
      </div>
    </div>
  );
};

const MapClick = ({onClick}) => {
  useMapEvent('dblclick', (e) => {
    onClick(e);
  });

  return null;
}