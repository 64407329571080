import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import ListPagination from '../../commons/components/ListPagination';
import Secured from '../../commons/components/Secured';
import CustomSpinner from '../../commons/components/Spinner';
import WarningMessage from '../../commons/components/WarningMessage';
import { handleApiErrors } from '../../commons/functions';
import CopyShopService from '../services/CopyShopService';
import { CopyShopFilterForm } from './CopyShopFilterForm';

export const CopyShopList = (props) => {

  const pageSize = 10;
  const intl = useIntl();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState({active: true});
  const [page, setPage] = useState({total: 0, list: []});

  const queryClient = useQueryClient();
  const result = useQuery(["copyShops", currentPage, pageSize, filter], () => CopyShopService.list(currentPage, pageSize, filter), {
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });
  const deleteMutation = useMutation((identifier) => CopyShopService.delete(identifier), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "copyShop.msg.delete"}));
      queryClient.invalidateQueries("copyShops");
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "copyShop.error.delete"))
  });

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  const onFilter = (data) => {
    setCurrentPage(0);
    setFilter(data);
  };

  const onAdd = (e) => {
    navigate("/copy-shops/new");
  };

  const onEdit = (id) => {
    navigate("/copy-shops/"+id);
  };

  const onDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const onSelect = (id) => {
    navigate(`/copy-shops/${id}/detail`);
  };

  let content = null;

  if (result.isLoading) {
    return (
      <CustomSpinner messageKey="copyShop.loading" />
    );
  } else if (result.isError) {
    return (
      <FormattedMessage id="copyShop.error.loading" />
    );
  } else {

    const items = page.list.map(item => 
      <CopyShopItem
        key={item.id}
        item={item} 
        onDelete={onDelete}
        onEdit={onEdit}
        onSelect={onSelect} />
    );
  
    let pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

    content = (
      <Fragment>
        <Table className='mt-3' responsive bordered hover>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="copyShop.field.name" />
              </th>
              <th>
                <FormattedMessage id="copyShop.field.address" />
              </th>
              <th>
                <FormattedMessage id="copyShop.field.license" />
              </th>
              <Secured>
                <th style={{width: "60px"}} />
              </Secured>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>
        {pagination}
      </Fragment>
    );
  }

  return (
    <div>
      <div className='d-flex justify-content-end align-items-top' style={{gap: "1rem"}}>
        <div>
          <CopyShopFilterForm onFilter={onFilter} filter={filter} />
        </div>
        <Secured>
          <div>
            <Button size='sm' disabled={result.isLoading || deleteMutation.isLoading} onClick={onAdd} title={intl.formatMessage({id: "copyShop.buttons.add"})}>
              <i className='fas fa-plus me-0 me-md-2' />
              <span className='d-none d-md-inline'>
                <FormattedMessage id="copyShop.buttons.add" />
              </span>
            </Button>
          </div>
        </Secured>
      </div>
      {content}
    </div>
  );
}

const CopyShopItem = ({item, onDelete, onEdit, onSelect}) => {

  const [openDelete, setOpenDelete] = useState(false);

  const toggleDelete = (e) => {
    setOpenDelete(!openDelete);
  };

  const handleConfirmOnDelete = (e) => {
    e.preventDefault();

    setOpenDelete(true);
  }

  const handleOnDelete = (e) => {
    e.preventDefault();

    onDelete(item.id);
    toggleDelete();
  }

  const handleOnEdit = (e) => {
    e.preventDefault();
    onEdit(item.id);
  }

  const handleOnSelect = (e) => {
    e.preventDefault();
    onSelect(item.id);
  };

  return (
    <tr>
      <td role="button" onClick={handleOnSelect}>{item.name}</td>
      <td role="button" onClick={handleOnSelect}>{item.address} - {item.postalCode} {item.cityName}</td>
      <td role="button" onClick={handleOnSelect}>{item.license}</td>
      <Secured>
        <td className='text-center'>
          <i className="fas fa-edit me-2" role="button" onClick={handleOnEdit}></i>
          <i className="fas fa-trash-alt" role="button" onClick={handleConfirmOnDelete}></i>
          <Modal isOpen={openDelete} toggle={toggleDelete} centered>
            <ModalHeader toggle={toggleDelete}>
              <FormattedMessage id="confirm" />
            </ModalHeader>
            <ModalBody>
              <WarningMessage messageKey="copyShop.delete.warning" />
              <FormattedMessage id="copyShop.delete" />
            </ModalBody>
            <ModalFooter>
              <div className='d-flex justify-content-end'>
                <Button color='danger' onClick={handleOnDelete}>
                  <FormattedMessage id="copyShop.buttons.delete" />
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </td>
      </Secured>
    </tr>
  );
}