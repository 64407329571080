import React from "react";
import { MainMenu } from "./MainMenu";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const Page = ({children}) => {

  const { auth } = useAuth();

  if (!auth) {
    return (
      <Navigate to="/login" />
    );
  }

  return (
    <div className='d-flex vh-100'>
      <MainMenu />
      <div className='flex-grow-1 pt-2 overflow-auto'>
        {children}
      </div>
    </div>
  );
};