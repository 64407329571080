import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';

export const NoMatchPage = (props) => {

  return (
      <Container fluid>
        <Row>
          <Col>
            <FormattedMessage id="notFound" />
          </Col>
        </Row>
      </Container>
  );
}