import React, { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import UserService from '../services/UserService';
import { toast } from "react-toastify";
import { useIntl } from 'react-intl';

export const ResetPasswordPage = (props) => {

  const intl = useIntl();
  const { resetPasswordKey } = useParams();

  useEffect(() => {

    if (resetPasswordKey) {

      UserService.resetPassword(resetPasswordKey)
      .then(response => {
        
        if (response.data && response.data.email) {
          toast.success(intl.formatMessage({ id: "forgotPassword.message.reset"}, {email: response.data.email}));
        }

      }).catch(error => {

      }).finally(() => {

      });

    }

  }, []);

  return (
    <Navigate to="/" />
  );
}