import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: {
      "Accept": "application/json",
      "Client": "copy-shop-dashboard"
    }
  },
  post: {
    'Content-Type': 'application/json'
  },
  put: {
    'Content-Type': 'application/json'
  }
});
api.interceptors.response.use((response) => response, (error) => {
  const statusCode = error.response ? error.response.status : null;
  if (statusCode === 401) {
    window.localStorage.removeItem("auth");
  }
  throw error;
});

export default api;
