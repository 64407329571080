import React, { Fragment, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Col, Container, Modal, ModalBody, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import BackButton from '../../commons/components/BackButton';
import { Page } from '../../commons/components/Page';
import CustomSpinner from '../../commons/components/Spinner';
import { isMobile } from '../../commons/functions';
import { CopyShopCalculator } from '../components/CopyShopCalculator';
import CopyShopDailyPrints from '../components/CopyShopDailyPrints';
import { CopyShpopDetail } from '../components/CopyShopDetail';
import { CopyShopHistory } from '../components/CopyShopHistory';
import { CopyShopTodayPrints } from '../components/CopyShopTodayPrints';
import CopyShopService from '../services/CopyShopService';
import PrintService from '../services/PrintService';

export const CopyShopDetailPage = (props) => {

  const { id } = useParams();

  const [item, setItem] = useState({});
  const [data, setData] = useState({});

  const result = useQuery(["copyshop", id], () => CopyShopService.get(id), {
    enabled: id !== undefined,
    onSuccess: (response) => setItem(response.data)
  });

  const resultTotal = useQuery(["copyshop-calculate-today", id], () => PrintService.calculateToday(id), {
    enabled: id !== undefined,
    onSuccess: (response) => setData(response.data)
  });

  if (result.isLoading) {
    return (
      <CustomSpinner messageKey="copyShop.detail.loading" />
    );
  }

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <BackButton />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <div className="d-block d-sm-flex justify-content-between">
              <h1 className='page-header fs-4 flex-grow-1'>{item.name}</h1>
              <Total data={data} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={8}>
            <CopyShopDailyPrints id={id} />
          </Col>
          <Col xs={12} lg={4}>
            <CopyShopTodayPrints id={id} />
          </Col>
        </Row>
        <CopyShopCalculator item={item} />
        <CopyShopHistory id={id} />
        <CopyShpopDetail item={item} />
      </Container>
    </Page>
  );
}

export const Total = ({data}) => {
  if (Object.keys(data).length === 0) {
    return null;
  }

  return (
    <div className='mb-3 mb-sm-0'>
      <div className="border border-secondary rounded-1 ps-2 pe-2">
        <div className='d-flex align-items-center'>
          <span className=''>
            <strong><FormattedMessage id="copyShop.extra.field.totalPages" />:</strong>&nbsp;<FormattedNumber value={data.pages} />
          </span>
          <span className='ms-2 me-2'>
            <strong><FormattedMessage id="copyShop.extra.field.level" />:</strong>&nbsp;{data.level}
          </span>
          <Info since={data.since}/>
        </div>
      </div>
    </div>
  );
};

export const Info = ({since}) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  const content = <FormattedMessage id="copyShop.extra.info" values={{date: since}} />

  let infoContent = null;
  const mobile = isMobile();

  if (mobile) {
    infoContent = (
      <Modal isOpen={open} toggle={toggle} centered>
        <ModalBody>
          {content}
        </ModalBody>
      </Modal>
    );
  } else {
    infoContent = (
      <UncontrolledPopover isOpen={open} target="total-info-icon" placement="bottom-start" >
        <PopoverBody>
          {content}
        </PopoverBody>
      </UncontrolledPopover>
    );
  }

  return (
    <Fragment>
      <i id="total-info-icon" className='fas fa-info-circle' role="button" onClick={mobile ? toggle : null} onMouseEnter={mobile ? null : toggle} onMouseLeave={mobile ? null : toggle} />
      {infoContent}
    </Fragment>
  );
  

};