import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'reactstrap';

const CustomSpinner = ({messageKey}) => {

  let message;

  if (messageKey) {
    message = (
      <div>
        <FormattedMessage id={messageKey} />
      </div>
    );
  }

  return (
    <div className="text-center">
      <div>
          <Spinner color="primary" style={{width: "4rem", height: "4rem"}}/>
      </div>
      {message}
    </div>
  );
};

export default CustomSpinner;