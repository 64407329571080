import moment from "moment";
import React, { Fragment, useContext } from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import { LanguageContext } from "../../commons/context/LanguageContext";

const OpeningHoursReadOnly = ({data}) => {
  
  const { language } = useContext(LanguageContext);

  if (!data || !Array.isArray(data)) {
    return null;
  }

  moment.updateLocale(language, {
    week: {
      dow: 1
    }
  });

  const weekdays = moment.weekdays(true);

  const days = weekdays.map((day, index) => <Day key={index} day={day} dayData={data?.[index]} />);

  return (
    <div className="small">
    {days}
    </div>
  );

};

export default OpeningHoursReadOnly;

const Day = ({day, dayData}) => {

    let hours = null, splitHours = null;
    if (dayData?.open === true) {
      hours = (
        <Fragment>
          <Moment parse="HH:mm:ss" format="HH:mm">{dayData?.from}</Moment>
          &nbsp;<FormattedMessage id="copyShop.openingHours.text.to" />&nbsp;
          <Moment parse="HH:mm:ss" format="HH:mm">{dayData?.to}</Moment>
        </Fragment>
      );
    }

    if (dayData?.split === true) {
      splitHours = (
        <Fragment>
          &nbsp;<FormattedMessage id="copyShop.openingHours.text.and" />&nbsp;
          <Moment parse="HH:mm:ss" format="HH:mm">{dayData?.fromSplit}</Moment>
          &nbsp;<FormattedMessage id="copyShop.openingHours.text.to" />&nbsp;
          <Moment parse="HH:mm:ss" format="HH:mm">{dayData?.toSplit}</Moment>
        </Fragment>
      );
    }

    return (
      <div className="d-flex flex-wrap">
        <strong className="text-capitalize">{day.replace(".", "")}:&nbsp;</strong>
        {!hours && !splitHours ? <Fragment><FormattedMessage id="copyShop.openingHours.text.closed" /></Fragment> : null }
        {hours}
        {splitHours}
      </div>
    )
};