import moment from "moment";
import React, { Fragment, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useQuery } from "react-query";
import { Button, Col, Row, Table, UncontrolledTooltip } from "reactstrap";
import ListPagination from "../../commons/components/ListPagination";
import Section from "../../commons/components/Section";
import CustomSpinner from "../../commons/components/Spinner";
import PrintService from "../services/PrintService";
import { CopyShopDayPrints } from "./CopyShopDayPrints";

export const CopyShopHistory = ({id}) => {
  
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState({total: 0, list: []});
  const [selectedDay, setSelectedDay] = useState();

  const result = useQuery(["prints", id, currentPage, pageSize], () => PrintService.dailyPage(id, currentPage, pageSize), {
    enabled: id !== undefined,
    onSuccess: (response) => setPage({total: response.data.total, list: response.data.data })
  });

  const clearDay = () => {
    setSelectedDay(null);
  }

  const onPageChange = (e, pageTotLoad) => {
    e.preventDefault();

    if (result.isLoading) {
      return;
    }

    setCurrentPage(pageTotLoad);
  }

  let content = null;

  if (result.isLoading) {
    content = (
      <CustomSpinner messageKey="copyShop.history.loading" />
    );
  } else if (result.isError) {
    content = (
      <FormattedMessage id="copyShop.history.error.loading" />
    );
  } else if (page.total === 0) {
    content = (
      <FormattedMessage id="copyShop.history.empty" />
    );
  } else {

    const onSelect = (item) => {
      setSelectedDay(item.day);
    }

    const items = page.list.map((item, index) => 
      <PrintItem key={index} item={item} onSelect={onSelect} />
    );
  
    let pagination = <ListPagination page={currentPage} totalItems={page.total} pageSize={pageSize} onPageChange={onPageChange} showSinglePage={false} />;

    content = (
      <Fragment>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="copyShop.history.field.created" />
              </th>
              <th className="text-end">
                <FormattedMessage id="copyShop.history.field.pages" />
              </th>
              <th style={{width: "95px"}}></th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>
        {pagination}
      </Fragment>
    );
  }

  return (
    <Section headerKey="copyShop.history.title" collapsible={true}>
      <Row>
        <Col xs="12" lg="6" xl="4">
          {content}
        </Col>
        {selectedDay ? 
        <Col xs="12" lg="6" xl={{size: 4, offset: 2}} >
          <div className="d-flex justify-content-between fw-bold mb-3">
            <FormattedMessage id="copyShop.history.details" values={{day: selectedDay}}/>
            <UncontrolledTooltip target="closeDetailButton">
              <FormattedMessage id="copyShop.history.buttons.closeDetails" />
            </UncontrolledTooltip>
            <i id="closeDetailButton" className='fas fa-times align-self-center' role="button" onClick={clearDay}/>
          </div>
          <CopyShopDayPrints id={id} day={moment(selectedDay, "DD/MM/YYYY").format("YYYY-MM-DD")} small={false} />
        </Col> : null }
      </Row>
    </Section>
  );
};

const PrintItem = ({item, onSelect}) => {

  const handleOnSelect = () => {
    onSelect && onSelect(item);
  }

  return (
    <tr>
      <td>{item.day}</td>
      <td className="text-end"><FormattedNumber value={item.pagesPrinted} /></td>
      <td>
        <Button size="sm" onClick={handleOnSelect}>
          <FormattedMessage id="copyShop.history.buttons.details" />
        </Button>
      </td>
    </tr>
  );
};