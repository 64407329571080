import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import CustomSpinner from "../../commons/components/Spinner";
import { handleApiErrors } from "../../commons/functions";
import CopyShopService from "../services/CopyShopService";

const CopyShopUserModal = ({identifier, disabled = false}) => {

  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [list, setList] = useState([]);
  const [userType, setUserType] = useState("OWNER");

  const queryClient = useQueryClient();
  
  const result = useQuery(["assocUsers", identifier, filter], () => CopyShopService.searchUsers(identifier, filter), {
    onSuccess: (response) => setList(response.data)
  });

  const assocMutation = useMutation(([userId, type]) => CopyShopService.linkUser(identifier, userId, type), {
    onSuccess: (response, vars, context) => {
      toast.success(intl.formatMessage({id: "copyShop.assocUser.msg.assoc"}));
      queryClient.invalidateQueries("linkedUsers");
      toggle();
      setFilter("");
    },
    onError: (error, vars, context) => toast.error(handleApiErrors(intl, error, "copyShop.assocUser.error.assoc"))
  });

  const toggle = () => {
    setOpen(!open);
  }

  const onFilter = (data) => {
    setFilter(data.search);
  };

  const onChangeType = (e) => {
    setUserType(e.target.value);
  };

  const onSelect = (user) => {
    assocMutation.mutate([user.id, userType]);
  };

  return (
    <Fragment>
      <Button onClick={toggle} disabled={disabled}>
        <i className='fas fa-plus me-0 me-md-2' />
        <span className='d-none d-md-inline'>
          <FormattedMessage id="copyShop.assocUser.buttons.add" />
        </span>
      </Button>
      <Modal isOpen={open} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <FormattedMessage id="copyShop.assocUser.title" />
        </ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <FormGroup className="me-3">
              <Input type="select" bsSize="sm" onChange={onChangeType} value={userType}>
                {CopyShopService.USER_TYPES.map((type, index) => <option key={index} value={type}>
                  <FormattedMessage id={"copyShop.assocUser.type." + type} />
                </option>)}
              </Input>
            </FormGroup>
            <AssocUserForm onFilter={onFilter} filter={filter} />
          </div>
          { !result.isLoading && list?.length === 0 && filter !== "" ? <div className="mt-2"><FormattedMessage id="copyShop.assocUser.search.empty" /></div> : null }
          { result.isLoading ? <div className="mt-2"><CustomSpinner messageKey="copyShop.assocUser.search.loading" /></div> : null }
          { !result.isLoading && list?.length !== 0 ? <div className="mt-2">
            <Table striped hover bordered>
              <thead>
                <tr>
                  <th><FormattedMessage id="copyShop.assocUser.field.email" /></th>
                  <th><FormattedMessage id="copyShop.assocUser.field.name" /></th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => {

                  return (
                    <AssocUserItem key={index} item={item} onSelect={onSelect} />
                  );

                })}
              </tbody>
            </Table>
          </div> : null }
        </ModalBody>
      </Modal>
    </Fragment>
  );

};

export default CopyShopUserModal;

const AssocUserForm = ({filter, onFilter}) => {
  const intl = useIntl();
  const { register, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues: {"search": filter}
  });

  const { ref, ...registerSearch } = register('search');

  return (
    <Form onSubmit={handleSubmit(onFilter)}>
      <FormGroup>
        <InputGroup>
          <Input id="search" bsSize="sm" innerRef={ref} {...registerSearch} placeholder={intl.formatMessage({id: "search"})} />
          <InputGroupText role="button" onClick={handleSubmit(onFilter)}>
              <i className="fas fa-search" aria-hidden="true" />
          </InputGroupText>
        </InputGroup>
      </FormGroup>
    </Form>
  );
};

const AssocUserItem = ({item, onSelect}) => {

  const handleOnClick = () => {
    onSelect(item);
  };

  return (
    <tr role="button" onClick={handleOnClick}>
      <td>{item.email}</td>
      <td>{item.fullName}</td>
    </tr>
  );

};