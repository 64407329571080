import moment from "moment";
import React, { Fragment, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { Button, Col, Form, Input, Label, Row, Table } from "reactstrap";
import { toast } from "react-toastify";
import { InputErrors } from "../../commons/components/InputErrors";
import MonthSelector from "../../commons/components/MonthSelector";
import CustomSpinner from "../../commons/components/Spinner";
import { LanguageContext } from "../../commons/context/LanguageContext";
import { currencySymbol, handleApiErrors } from "../../commons/functions";
import PrintService from "../services/PrintService";

import './CopyShopCalculator.css';
import CurrencyOutput from "../../commons/components/CurrencyOutput";
import Section from "../../commons/components/Section";

export const CopyShopCalculator = ({item}) => {

  const intl = useIntl();
  const [filter, setFilter] = useState({});
  const [advanced, setAdvanced] = useState(false);
  const [calculations, setCalculations] = useState({});
  const { register, setValue, reset, handleSubmit, formState: { submitting, errors } } = useForm({
    mode: "onSubmit"
  });

  const { ref: refStartDate, ...registerStartDate } = register('startDate', { required: true });
  const { ref: refEndDate, ...registerEndDate } = register('endDate', { required: true });

  const result = useQuery(["print-calculations", item.id, filter], () => PrintService.calculate(item.id, filter), {
    enabled: item && item.id !== undefined && Object.keys(filter).length !== 0,
    onSuccess: (response) => setCalculations(response.data),
    onError: (error, vars, context) => {
      toast.error(handleApiErrors(intl, error, "copyShop.calculator.error"));
    }
  });

  const onSubmit = (data) => {
    setFilter(data);
  };

  const onReset = (e) => {
    e?.preventDefault();
    reset();
    setFilter({});
    setCalculations({});
  }

  const onSelectMonth = (year, month) => {
    const days = moment().year(year).month(month).daysInMonth();
    const strMonth = String(month + 1).padStart(2, "0");
    setFilter({startDate: `${year}-${strMonth}-01`, endDate: `${year}-${strMonth}-${days}`});
    setValue("startDate", `${year}-${strMonth}-01`);
    setValue("endDate", `${year}-${strMonth}-${days}`);
  }

  const toggleAdvancedSearch = () => {
    setAdvanced(!advanced);
    onReset();
  }

  let calculationsOutput = null;
  if (Object.keys(calculations).length !== 0) {
    calculationsOutput = (
      <Calculations calculations={calculations} currency={item.currency} items={calculations.items} filter={filter} />
    );
  } else if (Object.keys(filter).length !== 0) {
    calculationsOutput = (
      <Calculations pages={0} amount={0} currency={item.currency} filter={filter} />
    );
  }
  
  return (
    <Fragment>
      <Section headerKey="copyShop.calculator.title">
        <Row>
          { !advanced ? 
          <Col xs="12" md="4" xl="3">
            <MonthSelector onSelect={onSelectMonth} disabled={result.isLoading} />
            <div className="text-muted small">
              <FormattedMessage id="copyShop.calculator.monthHelp" />
            </div>
            <Button color="link" size="sm" className="ps-0 text-decoration-none" onClick={toggleAdvancedSearch}>
              <FormattedMessage id="copyShop.calculator.buttons.advancedSearch" />
            </Button>
          </Col> : null }
          { advanced ? 
          <Col xs="12" md="4" xl="3" className="mt-3 mt-md-0">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Label for='startDate'>
                  <FormattedMessage id="copyShop.calculator.field.startDate" />
                </Label>
                <Input id="startDate"  name="startDate" type="date" innerRef={refStartDate} {...registerStartDate} disabled={result.isLoading}/>
                <InputErrors fieldName="startDate" errors={errors} />
              </div>
              <div>
                <Label for='endDate'>
                  <FormattedMessage id="copyShop.calculator.field.endDate" />
                </Label>
                <Input id="endDate"  name="endDate" type="date" innerRef={refEndDate} {...registerEndDate} disabled={result.isLoading} />
                <InputErrors fieldName="endDate" errors={errors} />
              </div>
              
              <div className="mt-2 d-flex justify-content-between">
                <div>
                  <Button className="ps-0" color="link" onClick={toggleAdvancedSearch}>
                      <FormattedMessage id="copyShop.calculator.buttons.basicSearch" />
                    </Button>
                </div>
                <div>
                  <Button color='primary' disabled={result.isLoading || submitting} type="submit">
                    <FormattedMessage id="copyShop.calculator.buttons.calculate" />
                  </Button>
                  <Button className="ms-2" type="reset" onClick={onReset} disabled={result.isLoading}>
                    <FormattedMessage id="copyShop.calculator.buttons.reset" />
                  </Button>
                </div>
              </div>
            </Form>
            
          </Col> : null}
          <Col xs="12" md="8" lg={{size: 7, offset: 1}} className="mt-3 mt-md-0">
            { calculationsOutput || result.isLoading ? null : <div className="d-flex align-items-center h-100 fw-bold"><FormattedMessage id={advanced ? "copyShop.calculator.selectPeriod" : "copyShop.calculator.selectMonth"} /></div> }
            {result.isLoading ? <CustomSpinner messageKey="copyShop.calculator.loading" /> : calculationsOutput}
          </Col>
        </Row>
      </Section>
    </Fragment>
  );
};

const Calculations = ({calculations, items, currency, filter}) => {

  const {language} = useContext(LanguageContext);
  
  const symbol = currencySymbol(currency);
  let detail = null;
  let title = (
    <FormattedMessage id="copyShop.calculator.detail.title" />
  );

  if (filter && Object.keys(filter).length !== 0) {
    const start = moment(filter.startDate, "YYYY-MM-DD");
    const end = moment(filter.endDate, "YYYY-MM-DD");

    const startOfMonth = moment(start).startOf("month",);
    const endOfMonth = moment(start).endOf("month");

    if (startOfMonth.isSame(start, "day") && endOfMonth.isSame(end, "day")) {
      title = (
        <h5 className="text-capitalize">{end.locale(language).format("MMMM YYYY")}</h5>
      );
    } else {
      title = (
        <h5 className="fs-4">
          <FormattedMessage id="copyShop.calculator.detail.title" />
          <span className="ms-2 fs-6">({start.format("DD/MM/YYYY")} - {end.format("DD/MM/YYYY")})</span>
        </h5>
      );
    }
  }

  if (calculations?.pages > 0) {

    const vatKeys = Object.keys(calculations.vatAmounts);

    detail = (
      <div>
        <div className="d-flex justify-content-between border-bottom border-secondary-light mb-3">
          <div>
            {title}
          </div>  
          <div>
            <span className="me-2"><FormattedMessage id="copyShop.calculator.detail.pages"/>:</span>
            <FormattedNumber value={calculations.pages} />
          </div>
        </div>
        <div>
          <LinesTable items={items} currencySymbol={symbol} vatKeys={vatKeys} />
          <div className="d-flex justify-content-end">
            <SummaryTable calculations={calculations} currencySymbol={symbol} vatKeys={vatKeys} />
          </div>
        </div>
      </div>
    );
  } else {
    detail = (
      <div>
        <div className="d-flex justify-content-between border-bottom border-secondary-light mb-3">
            {title}
        </div>
        <div className="fw-bold">
          <FormattedMessage id="copyShop.calculator.detail.noPrints" />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {detail}
    </Fragment>
  );

};

const SummaryTable = ({calculations, vatKeys, currencySymbol}) => {
  return (
    <Table className="summary-table bordered-row">
      <tbody>
        <tr>
          <th scope="row" className="text-end"><FormattedMessage id="copyShop.calculator.detail.col.total" /></th>
          <td className="text-end"><CurrencyOutput value={calculations.amount} currencySymbol={currencySymbol} maximumFractionDigits={2} /></td>
        </tr>
        <VatList calculations={calculations} vatKeys={vatKeys} currencySymbol={currencySymbol} />
        <tr>
          <th scope="row" className="text-end"><FormattedMessage id="copyShop.calculator.detail.col.totalVat" /></th>
          <td className="text-end"><CurrencyOutput value={calculations.total} currencySymbol={currencySymbol} maximumFractionDigits={2} /></td>
        </tr>
      </tbody>
    </Table>
  );
};

const LinesTable = ({items, vatKeys, currencySymbol}) => {
  
  return (
    <Table className="detail-table bordered-row">
      <thead>
          <tr>
            <th scope="col" style={{width: "33.3333%"}} className="text-end"><FormattedMessage id="copyShop.calculator.detail.col.pages" /></th>
            <th scope="col" style={{width: "33.3333%"}} className="text-end"><FormattedMessage id="copyShop.calculator.detail.col.price" /></th>
            <th scope="col" style={{width: "33.3333%"}} className="text-end"><FormattedMessage id="copyShop.calculator.detail.col.total" /></th>
          </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {

          const vatIndex = vatKeys.findIndex(i => i === item.vatPercentage.toFixed(2));

          return (
            <tr key={index}>
              <td className="text-end">{vatIndex === -1 || vatKeys.length <= 1 ? null : <VatIndex index={vatIndex + 1} />}<FormattedNumber value={item.pages} /></td>
              <td className="text-end"><CurrencyOutput value={item.pricePerPage} currencySymbol={currencySymbol} /></td>
              <td className="text-end"><CurrencyOutput value={item.total} currencySymbol={currencySymbol} /></td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const VatList = ({calculations, vatKeys, currencySymbol}) => {

  return vatKeys.map((key, index) => {
    return (
      <tr key={index}>
        <th scope="row" className="text-end">
          <FormattedMessage id="copyShop.calculator.detail.col.vat" />
          &nbsp;
          {vatKeys.length <= 1 ? null : <Fragment><sup>({index + 1})</sup>&nbsp;</Fragment>}
          (<FormattedNumber value={key} minimumFractionDigits={0} maximumFractionDigits={2} />%)
        </th>
        <td className="text-end">
          <CurrencyOutput value={calculations.vatAmounts[key]} currencySymbol={currencySymbol} maximumFractionDigits={2} />
          </td>
      </tr>
    );
  });
};

const VatIndex = ({index}) => {
  return (
    <Fragment><sup style={{float: "left", top: ".5em"}}>({index})</sup></Fragment>
  );
}