import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import Secured from "../../commons/components/Secured";

export const CopyShopFilterForm = ({filter, onFilter}) => {

  const intl = useIntl();
  const { register, reset, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: filter
  });
  const { ref, ...registerSearch } = register('search');
  const { ref: refActive, ...registerActive } = register('active');

  useEffect(() => {
    reset(filter);
  }, [filter]);

  const onSubmit = (data) => {
    onFilter(data);
  };

  const onClear = (e) => {
    if (filter.search) {
      setValue("search", "");
    }
    handleSubmit(onSubmit)();
  }
 
  const handleActiveChange = () => {
    setValue("active", !filter.active);
    handleSubmit(onSubmit)();
  }

  let icon = "fas fa-search";
  if (filter.search) {
    icon = "fas fa-times";
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex">
        <Secured>
          <FormGroup switch className="align-self-center mb-0 me-3">
            <Input type="switch" role="switch" className="me-1" innerRef={refActive} {...registerActive} onClick={handleActiveChange} />
            <Label check onClick={handleActiveChange}><FormattedMessage id="active" /></Label>
          </FormGroup>
        </Secured>
        <div>
          <InputGroup>
            <Input id="search" bsSize="sm" innerRef={ref} {...registerSearch} placeholder={intl.formatMessage({id: "search"})} />
            <InputGroupText role="button" onClick={onClear}>
                <i className={icon} aria-hidden="true" />
            </InputGroupText>
          </InputGroup>
        </div>
      </div>
    </Form>
  );
};