import api from "../../Api";
import { buildApiHeaders } from "../../commons/functions";

class CopyShopService {

  static async list(page, pageSize, filter) {
    const params = Object.assign({page: page, pageSize: pageSize}, filter);
    const headers = buildApiHeaders();
    return api.get("/v1/admin/copy-shops", {params: params, headers: headers});
  }

  static async get(identifier) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/copy-shops/${identifier}`, {headers: headers});
  }

  static async create(data) {
    const headers = buildApiHeaders();
    return api.put("/v1/admin/copy-shops", data, {headers: headers});
  }

  static async update(identifier, data) {
    const headers = buildApiHeaders();
    return api.post(`/v1/admin/copy-shops/${identifier}`, data, {headers: headers});
  }

  static async delete(identifier) {
    const headers = buildApiHeaders();
    return api.delete(`/v1/admin/copy-shops/${identifier}`, {headers: headers});
  }

  static async searchUsers(identifier, filter) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/copy-shops/${identifier}/assoc-user`, {params: {"filter": filter}, headers: headers});
  }

  static async linkedUsers(identifier) {
    const headers = buildApiHeaders();
    return api.get(`/v1/admin/copy-shops/${identifier}/users`, {headers: headers});
  }

  static async linkUser(identifier, userId, type) {
    const headers = buildApiHeaders();
    return api.post(`/v1/admin/copy-shops/${identifier}/assoc-user`, {"userId": userId, "type": type}, {headers: headers});
  }

  static async deleteLink(identifier, userId) {
    const headers = buildApiHeaders();
    return api.delete(`/v1/admin/copy-shops/${identifier}/assoc-user/${userId}`, {headers: headers});
  }

  static USER_TYPES = [
    "OWNER", "MANAGER", "OTHER"
  ];
}

export default CopyShopService;