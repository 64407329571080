import { useContext } from "react";
import { LanguageContext } from '../context/LanguageContext';

const languages = ["en", "fr", "nl"];

export const LanguageSelector = ({theme = "dark", collapsed = false}) => {
  const { language, setLanguage } = useContext(LanguageContext);
  
  const items = languages.map(lang => {
    return (
      <LanguageItem language={lang} selected={language} key={lang} onSelect={setLanguage} theme={theme} />
    );
  });

  let rootClass = "flex-column flex-md-row";
  if (collapsed) {
    rootClass = "flex-column";
  }

  return (
    <div className={`d-flex ${rootClass} p-2`} style={{gap: "10px"}}>
      {items}
    </div>
  );
};

const LanguageItem = ({language, selected, onSelect, theme}) => {
  
  let selectedClass = " bg-zc-light text-secondary";
  let baseClass = "text-white";
  if (theme !== "dark") {
    selectedClass = " bg-zc text-white";
    baseClass = "text-secondary";
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    onSelect(language);
  }

  return (
    <div className={`p-2 rounded text-center ${baseClass}${selected === language ? selectedClass:''}`} onClick={handleOnClick} role="button">
      <span className="text-uppercase">{language}</span>
    </div>
  );
};