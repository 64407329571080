import api from "../../Api";

class LoginService {

   static async login(username, password) {
     return api.post("/v1/login", {"username": username, "password": password});
   }

   static async logout(token) {
    return api.post("/v1/logout", {}, {headers: {'X-AUTH-TOKEN': token}});
  }
}

export default LoginService;