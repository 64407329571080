import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { InputErrors } from "../../commons/components/InputErrors";
import { DEFAULT_MAX_LENGTH } from "../../commons/validations";

export const PricingConfigForm = ({id, onSave, defaultValues, disabled = false}) => {

  const { register, handleSubmit, setFocus, formState: { errors } } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  const { ref: refName, ...registerName } = register('name', { required: true, maxLength: DEFAULT_MAX_LENGTH });
  const { ref: refStartDate, ...registerStartDate } = register('startDate', { required: true });
  const { ref: refEndDate, ...registerEndDate } = register('endDate', { required: true });

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <Form id={id} onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for='name'>
          <FormattedMessage id="pricing.pricingConfig.field.name" />
        </Label>
        <Input id="name"  name="name" innerRef={refName} {...registerName} disabled={disabled} />
        <InputErrors fieldName="name" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='startDate'>
          <FormattedMessage id="pricing.pricingConfig.field.startDate" />
        </Label>
        <Input id="startDate"  name="startDate" type="date" innerRef={refStartDate} {...registerStartDate} disabled={disabled} />
        <InputErrors fieldName="startDate" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='name'>
          <FormattedMessage id="pricing.pricingConfig.field.endDate" />
        </Label>
        <Input id="endDate"  name="endDate" type="date" innerRef={refEndDate} {...registerEndDate} disabled={disabled} />
        <InputErrors fieldName="endDate" errors={errors} />
      </FormGroup>
    </Form>
  );
};