import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { Page } from '../../commons/components/Page';

export const InvoiceInfoPage = (props) => {

  return (
    <Page>
      <Container fluid>
        <Row>
          <Col>
            <h1 className='page-header fs-4'><FormattedMessage id="invoiceInfo.title" /></h1>
          </Col>
        </Row>
        <Row>
          <Col>
          <div>
            <h2 className='section-title fs-5'><FormattedMessage id="invoiceInfo.zerocopy" /></h2>
            <div className='section-content'>
              <ul>
                <li><strong><FormattedMessage id="invoiceInfo.zerocopy.field.name" /></strong> Zerocopy BV</li>
                <li><strong><FormattedMessage id="invoiceInfo.zerocopy.field.address" /></strong> Cantersteen 10, 1000 Brussels</li>
                <li><strong><FormattedMessage id="invoiceInfo.zerocopy.field.email" /></strong> billing@zerocopy.be</li>
                <li><strong><FormattedMessage id="invoiceInfo.zerocopy.field.vat" /></strong> BE 0846.192.267</li>
                <li><strong><FormattedMessage id="invoiceInfo.zerocopy.field.website" /></strong> www.zerocopy.be</li>
              </ul>
            </div>
          </div>
          <div className='mt-3'>
            <h2 className='section-title fs-5'><FormattedMessage id="invoiceInfo.invoice" /></h2>
            <div className='section-content'>
              <ol>
                <li><FormattedMessage id="invoiceInfo.invoice.line1" values={{
                  breakLine: (<br/>),
                  b: (text) => (<b>{text}</b>),
                }} /></li>
                <li><FormattedMessage id="invoiceInfo.invoice.line2" /></li>
                <li><FormattedMessage id="invoiceInfo.invoice.line3" /></li>
                <li><FormattedMessage id="invoiceInfo.invoice.line4" values={{
                  li: (item) => (<li>{item}</li>),
                  ul: (...chunks) => (<ul>{chunks}</ul>)
                }}/></li>
                <li><FormattedMessage id="invoiceInfo.invoice.line5" /></li>
                <li><FormattedMessage id="invoiceInfo.invoice.line6" /></li>
              </ol>
             
              <strong><FormattedMessage id="invoiceInfo.invoice.important" /></strong>
              <ul>
                <li><FormattedMessage id="invoiceInfo.invoice.extra" /></li>
              </ul>
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}