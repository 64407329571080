import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { Button, Form, Input, Label, FormGroup, Spinner } from "reactstrap";
import { InputErrors } from "../../commons/components/InputErrors";
import { PasswordInput } from "../../commons/components/PasswordInput";
import { useAuth } from "../../commons/hooks/useAuth";
import { DEFAULT_MAX_LENGTH } from "../../commons/validations";
import LoginService from "../services/LoginService";

export const LoginForm = (props) => {

  const {login} = useAuth();
  
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { submitting, errors } } = useForm({
    mode: "onSubmit"
  });

  const { ref: refUsername, ...registerUsername } = register('username', { required: true, maxLength: DEFAULT_MAX_LENGTH });
  const { ref: refPassword, ...registerPassword } = register('password', { required: true, maxLength: DEFAULT_MAX_LENGTH });

  const onSubmit = (data) => {
    setLoading(true);
    LoginService.login(data.username, data.password).then(response => {
      login(response.data);
    }).catch(error => {
      toast.error(intl.formatMessage({id: "login.error"}));
    }).finally(() => {
      setLoading(false);
    });

  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for='username'>
          <FormattedMessage id="login.username" />
        </Label>
        <Input id="username" autoFocus innerRef={refUsername} {...registerUsername} placeholder={intl.formatMessage({id: "login.username.placeholder"})} />
        <InputErrors fieldName="username" errors={errors} />
      </FormGroup>
      <FormGroup>
        <Label for='password'>
          <FormattedMessage id="login.password" />
        </Label>
        <PasswordInput id="password" innerRef={refPassword} {...registerPassword} placeholder={intl.formatMessage({id: "login.password.placeholder"})} />
        <InputErrors fieldName="password" errors={errors} />
      </FormGroup>
      <div className='mt-2 d-grid'>
        <Button color='primary' disabled={ submitting || loading } type="submit">
          <FormattedMessage id="login.buttons.login" />
          {submitting || loading?<Spinner size="sm" className="ms-2"/>:null}
        </Button>
      </div>
    </Form>
  );
};