import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import BackButton from '../../commons/components/BackButton';
import SecuredPage from '../../commons/components/SecuredPage';
import { CopyShopForm } from '../components/CopyShopForm';

export const CopyShopFormPage = (props) => {

  const { id } = useParams();

  return (
    <SecuredPage>
      <Container fluid>
        <Row>
          <Col>
            <BackButton />
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col>
            <h1 className='page-header fs-4'><FormattedMessage id={id?"copyShop.edit.title":"copyShop.add.title"} /></h1>
          </Col>
        </Row>
          <CopyShopForm id={id} />
      </Container>
    </SecuredPage>
  );
}